import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Metric, MetricCategory } from '../../../../models';
import { MetricStructureSelectable, ValueDefinitionGroupOrMetricTableGroup } from '../../../models';
import { ExpandableCardComponent } from '../../../../components';
import { ValueDefinitionGroupUtils } from '../../../classes/ValueDefinitionGroupUtils/value-definition-group-utils';

@Component({
  selector: 'lib-metric-structure-reference-v2-sections',
  templateUrl: './metric-structure-reference-v2-sections.component.html',
  styleUrls: ['./metric-structure-reference-v2-sections.component.scss'],
})
export class MetricStructureReferenceV2SectionsComponent implements OnChanges {
  @Input({ required: true }) groupsOrTables!: ValueDefinitionGroupOrMetricTableGroup[];
  @Input({ required: true }) metric!: Metric;
  @Input() selectedItem?: MetricStructureSelectable;

  @ViewChild('customizableCard') customizableCard?: ExpandableCardComponent;

  readonly eMetricCategory: typeof MetricCategory = MetricCategory;

  public showReferenceSectionWarning: boolean = false;

  public updateShowReferenceSectionWarning(value: boolean): void {
    this.showReferenceSectionWarning = value;
  }

  public coreGroupOrTableLength!: number;

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (!this.customizableCard?.isExpanded && this.newGroupOrTableAdded()) {
      this.customizableCard?.toggleExpand(true);
    }
    if (simpleChanges.groupsOrTables) {
      this.coreGroupOrTableLength = simpleChanges.groupsOrTables.currentValue?.filter(
        ValueDefinitionGroupUtils.isGroupOrTableFromCore,
      ).length;
    }
  }

  trackId(index: number, item: { id?: string }): string | undefined {
    return item.id;
  }

  public filterOutReferenceVDGOrTable(valueDefinitionGroup: ValueDefinitionGroupOrMetricTableGroup): boolean {
    return ValueDefinitionGroupUtils.isGroupOrTableFromCore(valueDefinitionGroup);
  }

  public filterOutCustomizableVDGOrTable(valueDefinitionGroup: ValueDefinitionGroupOrMetricTableGroup): boolean {
    return !ValueDefinitionGroupUtils.isGroupOrTableFromCore(valueDefinitionGroup);
  }

  private newGroupOrTableAdded(): boolean {
    return this.selectedItem
      ? this.groupsOrTables.find(
          (vdgOrTable: ValueDefinitionGroupOrMetricTableGroup) =>
            vdgOrTable.id === this.selectedItem?.id || vdgOrTable.table_id === this.selectedItem?.id,
        ) != undefined
      : false;
  }
}
