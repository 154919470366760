import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AddRelatedFieldDialogForm } from './add-related-field-dialog-form';
import {
  DialogResult,
  Metric,
  MetricCategory,
  MetricTableValueSelectionChoice,
  MetricValueDefinitionSelection,
  RelatedField,
  Status,
  ValueDefinitionFieldType,
} from '../../../../../models';
import { MetricApiService } from '../../../../../services/types';
import keyBy from 'lodash/keyBy';

export interface AddRelatedFieldDialogComponentConfig {
  id: string;
  metric_id: string;
  relatedFields: RelatedField[];
  value_definition_group_id?: string;
}

export type AddRelatedFieldDialogComponentResult = DialogResult<{ relatedFields: RelatedField[] }>;

enum AddRelatedFieldDialogTabs {
  STEP1,
  STEP2,
}

@Component({
  selector: 'lib-add-related-field-dialog',
  templateUrl: './add-related-field-dialog.component.html',
  styleUrls: ['./add-related-field-dialog.component.scss'],
})
export class AddRelatedFieldDialogComponent {
  @ViewChild('step1') step1?: TemplateRef<undefined>;
  @ViewChild('step2') step2?: TemplateRef<undefined>;

  public readonly eAddRelatedFieldDialogTabs = AddRelatedFieldDialogTabs;
  public readonly eMetricCategory = MetricCategory;
  public readonly eMetricTableValueSelectionChoice = MetricTableValueSelectionChoice;
  public readonly supportedFieldTypes = [
    ValueDefinitionFieldType.number,
    ValueDefinitionFieldType.calculated,
    ValueDefinitionFieldType.boolean,
    ValueDefinitionFieldType.choice,
    ValueDefinitionFieldType.table,
    ValueDefinitionFieldType.text,
    ValueDefinitionFieldType.textarea,
    ValueDefinitionFieldType.rich_text,
    ValueDefinitionFieldType.date,
    ValueDefinitionFieldType.file_v2,
    ValueDefinitionFieldType.tip,
    ValueDefinitionFieldType.subtitle,
    ValueDefinitionFieldType.document,
  ];

  public form: AddRelatedFieldDialogForm;
  public ignoreFields: string[];
  public selectedIndex: AddRelatedFieldDialogTabs = AddRelatedFieldDialogTabs.STEP1;
  public selections: Record<string, MetricValueDefinitionSelection> = {};

  constructor(
    private readonly metricsService: MetricApiService,
    private dialogRef: MatDialogRef<AddRelatedFieldDialogComponentConfig, AddRelatedFieldDialogComponentResult>,
    @Inject(MAT_DIALOG_DATA) public readonly data: AddRelatedFieldDialogComponentConfig,
  ) {
    this.form = new AddRelatedFieldDialogForm();
    this.ignoreFields = data.relatedFields.map((r) =>
      String(r.to_value_definition_id || r.to_metric_table_definition_id),
    );
  }

  public closeDialog(): void {
    this.dialogRef.close({ status: Status.CANCEL });
  }

  public rowSelected(selection: MetricValueDefinitionSelection): void {
    const selections = [...this.form.controls.items.value];
    const newSelections = selections.filter(
      (s) =>
        (!s.tableId || s.tableId !== selection.tableId) &&
        (!s.valueDefinitionId || s.valueDefinitionId !== selection.valueDefinitionId),
    );

    if (selections.length === newSelections.length) {
      newSelections.push(selection);
    }

    this.form.controls.items.setValue(newSelections);
    this.selections = keyBy(newSelections, 'valueDefinitionId');
  }

  public save(): void {
    const { id, metric_id, value_definition_group_id } = this.data;
    const payload = this.form.toModel();
    let obs;

    if (value_definition_group_id) {
      obs = this.metricsService.addFieldRelatedFields(metric_id, value_definition_group_id, id, payload);
    } else {
      obs = this.metricsService.addMetricTableRelatedFields(metric_id, id, payload);
    }

    obs.subscribe((res) => {
      const relatedFields = res.data.filter(
        (fr) => fr.from_value_definition_id === id || fr.from_metric_table_definition_id === id,
      );
      this.dialogRef.close({ status: Status.SUCCESS, data: { relatedFields } });
    });
  }

  public selectedIndexChange(index: AddRelatedFieldDialogTabs): void {
    this.selectedIndex = index;
  }

  public selectMetric(metric: Metric): void {
    if (this.form.controls.metric.value?.id !== metric.id) {
      this.form.controls.items.setValue([]);
    }

    this.form.controls.metric.setValue(metric);
  }
}
