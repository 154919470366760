import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-landing-page-title',
  templateUrl: './landing-page-title.component.html',
  styleUrl: './landing-page-title.component.scss',
})
export class LandingPageTitleComponent {
  @Input() title: string = '';
}
