import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '../../services/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonTypeEnum } from '../../models';

@Component({
  selector: 'lib-info-dialog-v2',
  templateUrl: './info-dialog-v2.component.html',
  styleUrls: ['./info-dialog-v2.component.scss'],
})
export class InfoDialogV2Component implements OnInit {
  public title: string = '';
  public body: string = '';
  public error: string = '';
  public button: string = '';
  public buttonType: keyof typeof ButtonTypeEnum = ButtonTypeEnum.success;
  public cancelLabel: string = '';
  public modal: boolean = false;

  constructor(
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
    this.title = this.data.title || this.translateService.instant('Information!');
    this.body = this.data.body;
    this.error = this.data.error;
    this.button = this.data.button || this.translateService.instant('OK');
    this.buttonType = this.data.buttonType || ButtonTypeEnum.success;
    this.cancelLabel = this.data.cancelLabel || this.translateService.instant('Cancel');
    this.modal = this.data.modal || false;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public confirm(): void {
    this.dialogRef.close({ confirmed: true });
  }
}
