<div class="formats">
  <form [formGroup]="formGroup">
    <!-- text area without validation -->
    <div *ngIf="data.dataFormatType === valueDefinitionType.text_area; else textAreaWithValidation">
      <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
      <mat-form-field class="w-100 superscript-hint" floatLabel="auto">
        <textarea
          rows="5"
          matInput
          formControlName="textAreaControl"
          class="color-grey-900"
          [cdkTextareaAutosize]="true"
          id="{{ data.id }}"
          [placeholder]="data.data.type_details.placeholder ?? ''"
          libStripNonPrintableCharacters
          >{{ data.data.value }}</textarea
        >
        <mat-hint class="w-100 fx-row">
          <div>{{ data.data.hint }}</div>
        </mat-hint>
        <mat-error *ngIf="formGroup.controls['textAreaControl'].hasError('required')">
          {{ requiredErrorMsg }}
        </mat-error>
        <mat-error *ngIf="formGroup.controls['textAreaControl'].hasError('maxlength')">
          {{
            "maximum length can be {count}"
              | translate: { count: formGroup.controls["textAreaControl"].errors?.maxlength?.requiredLength }
          }}
        </mat-error>
        <mat-error *ngIf="formGroup.controls['textAreaControl'].hasError('minlength')">
          {{
            "minimum length should be {count}"
              | translate: { count: formGroup.controls["textAreaControl"].errors?.minlength?.requiredLength }
          }}
        </mat-error>
      </mat-form-field>
    </div>

    <!-- text area with validation -->
    <ng-template #textAreaWithValidation>
      <ng-container *ngIf="data.dataFormatType === valueDefinitionType.text_area_validation; else richTextFormat">
        <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
        <mat-form-field class="w-100 superscript-hint" floatLabel="auto">
          <textarea
            rows="5"
            matInput
            #textArea
            [cdkTextareaAutosize]="true"
            [maxlength]="maxCharacterCount"
            formControlName="textAreaWithValidation"
            class="color-grey-900"
            [placeholder]="data.data.type_details.placeholder ?? ''"
            libStripNonPrintableCharacters
            id="{{ data.id }}"
            >{{ data.data.value?.substring(0, maxCharacterCount) }}</textarea
          >
          <mat-hint class="data-formats-header w-100 fx-row justify-space-between">
            <div>{{ data.data.hint }}</div>
            <div>{{ textArea.value.length }}/{{ maxCharacterCount }}</div>
          </mat-hint>
          <mat-error *ngIf="formGroup.controls['textAreaWithValidation'].hasError('required')">
            {{ requiredErrorMsg }}
          </mat-error>
          <mat-error *ngIf="formGroup.controls['textAreaWithValidation'].hasError('maxlength')">
            {{
              "maximum length can be {count}"
                | translate: { count: formGroup.controls["textAreaWithValidation"].errors?.maxlength?.requiredLength }
            }}
          </mat-error>
          <mat-error *ngIf="formGroup.controls['textAreaWithValidation'].hasError('minlength')">
            {{
              "minimum length should be {count}"
                | translate: { count: formGroup.controls["textAreaWithValidation"].errors?.minlength?.requiredLength }
            }}
          </mat-error>
        </mat-form-field>
      </ng-container>
    </ng-template>

    <!-- rich text format -->
    <ng-template #richTextFormat>
      <ng-container *ngIf="data.dataFormatType === valueDefinitionType.text_rich; else textInputFormat">
        <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
        <div class="rich-text-formfield">
          <div id="{{ data.id }}">
            <lib-rich-text-input
              [control]="editorControl"
              class="{{ data.id }}"
              [ngClass]="{
                error: editorControl.touched && editorControl.invalid,
              }"
              [placeholder]="data.data.type_details.placeholder"
            ></lib-rich-text-input>
          </div>
          <mat-hint *ngIf="editorControl.valid">{{ data.data.hint }}</mat-hint>
          <mat-error *ngIf="editorControl.touched && editorControl.hasError('required')">
            {{ requiredErrorMsg }}
          </mat-error>
          <mat-error *ngIf="editorControl.touched && editorControl.hasError('maxlength')">
            {{
              "maximum length can be {count}"
                | translate: { count: formGroup.controls["editorControl"].errors?.maxlength?.requiredLength }
            }}
          </mat-error>
          <mat-error *ngIf="editorControl.touched && editorControl.hasError('minlength')">
            {{
              "minimum length should be {count}"
                | translate: { count: formGroup.controls["editorControl"].errors?.minlength?.requiredLength }
            }}
          </mat-error>
        </div>
      </ng-container>
    </ng-template>

    <!-- text input -->
    <ng-template #textInputFormat>
      <ng-container *ngIf="data.dataFormatType === valueDefinitionType.text_simple; else numberFormat">
        <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
        <mat-form-field class="w-100" floatLabel="auto">
          <input
            matInput
            formControlName="textInputFormat"
            class="color-grey-900"
            id="{{ data.id }}"
            [placeholder]="data.data.type_details.placeholder ?? ''"
          />
          <mat-hint>{{ data.data.hint }}</mat-hint>
          <mat-error *ngIf="formGroup.controls['textInputFormat'].hasError('required')">
            {{ requiredErrorMsg }}
          </mat-error>
          <mat-error *ngIf="formGroup.controls['textInputFormat'].hasError('maxlength')">
            {{
              "maximum length can be {count}"
                | translate: { count: formGroup.controls["textInputFormat"].errors?.maxlength?.requiredLength }
            }}
          </mat-error>
          <mat-error *ngIf="formGroup.controls['textInputFormat'].hasError('minlength')">
            {{
              "minimum length should be {count}"
                | translate: { count: formGroup.controls["textInputFormat"].errors?.minlength?.requiredLength }
            }}
          </mat-error>
        </mat-form-field>
      </ng-container>
    </ng-template>

    <!-- number Format -->
    <ng-template #numberFormat>
      <ng-container
        *ngIf="
          data.dataFormatType === valueDefinitionType.number || data.dataFormatType === valueDefinitionType.calculated;
          else choiceField
        "
      >
        <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
        <mat-form-field class="w-100 m-0" floatLabel="auto">
          <input
            matInput
            formControlName="numberControl"
            value="{{ data.data.value }}"
            class="color-grey-900"
            id="{{ data.id }}"
            (input)="roundOff($event)"
            [placeholder]="data.data.type_details.placeholder ?? ''"
          />
          <div matSuffix class="color-grey-900">{{ symbol }}</div>
          <mat-hint>{{ data.data.hint }}</mat-hint>
          <mat-error
            *ngIf="
              !formGroup.controls['numberControl'].hasError('required') &&
              (formGroup.controls['numberControl'].hasError('pattern') ||
                formGroup.controls['numberControl'].hasError('notNumeric'))
            "
          >
            {{ numberValidationErrorMsg }}
            {{ "decimal" | translate }}
          </mat-error>
          <mat-error *ngIf="formGroup.controls['numberControl'].hasError('required')">
            {{ requiredErrorMsg }}
          </mat-error>
          <mat-error *ngIf="formGroup.controls['numberControl'].hasError('maxlength')">
            {{
              "maximum length can be {count}"
                | translate: { count: formGroup.controls["numberControl"].errors?.maxlength?.requiredLength }
            }}
          </mat-error>
          <mat-error *ngIf="formGroup.controls['numberControl'].hasError('minlength')">
            {{
              "minimum length should be {count}"
                | translate: { count: formGroup.controls["numberControl"].errors?.minlength?.requiredLength }
            }}
          </mat-error>
          <mat-error *ngIf="formGroup.controls['numberControl'].hasError('max')">
            {{
              "maximum value limit is {count}"
                | translate: { count: formGroup.controls["numberControl"].errors?.max?.max }
            }}
          </mat-error>
          <mat-error *ngIf="formGroup.controls['numberControl'].hasError('min')">
            {{
              "minimum value expected is {count}"
                | translate: { count: formGroup.controls["numberControl"].errors?.min?.min }
            }}
          </mat-error>
        </mat-form-field>
      </ng-container>
    </ng-template>

    <!-- choice field -->
    <ng-template #choiceField>
      <ng-container *ngIf="isChoiceField(); else boolean">
        <lib-data-formats-choice-field
          [data]="data"
          controlName="selectControl"
          (changed)="returnValues()"
          (delete)="deleteValue()"
        ></lib-data-formats-choice-field>
      </ng-container>
    </ng-template>

    <!-- boolean format -->
    <ng-template #boolean>
      <ng-container *ngIf="data.dataFormatType === valueDefinitionType.boolean; else datePickerFormat">
        <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
        <mat-form-field class="w-100" floatLabel="auto">
          <mat-label>{{ data.data.type_details.placeholder }}</mat-label>
          <mat-select formControlName="booleanControl" id="{{ data.id }}" (selectionChange)="selectionChange($event)">
            <div>
              <mat-option [value]="true">{{ "Yes" | translate }}</mat-option>
              <mat-option [value]="false">{{ "No" | translate }}</mat-option>
            </div>
          </mat-select>
          <mat-hint>{{ data.data.hint }}</mat-hint>
          <mat-error *ngIf="formGroup.controls['booleanControl'].hasError('required')">
            {{ requiredErrorMsg }}
          </mat-error>
        </mat-form-field>
        <ng-container *ngIf="formGroup.controls.conditionalInputControl.enabled">
          <ng-container *ngTemplateOutlet="conditionalFields"> </ng-container>
        </ng-container>
      </ng-container>
    </ng-template>

    <!--date picker format-->
    <ng-template #datePickerFormat>
      <ng-container *ngIf="data.dataFormatType === valueDefinitionType.date; else tip">
        <lib-data-formats-label [data]="data" (delete)="deleteValue()"></lib-data-formats-label>
        <mat-form-field class="w-100" floatLabel="auto">
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="dateControl"
            class="color-grey-900"
            id="{{ data.id }}"
            [placeholder]="data.data.type_details.placeholder ?? ''"
          />
          <mat-datepicker-toggle matSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-hint>{{ data.data.hint }}</mat-hint>
          <mat-error *ngIf="formGroup.controls['dateControl'].hasError('invalidDate')">{{
            "invalid date" | translate
          }}</mat-error>
          <mat-error *ngIf="formGroup.controls['dateControl'].hasError('required')">{{
            "missing date" | translate
          }}</mat-error>
        </mat-form-field>
      </ng-container>
    </ng-template>

    <!--tip-->
    <ng-template #tip>
      <ng-container *ngIf="data.dataFormatType === valueDefinitionType.tip; else label">
        <lib-data-formats-tip [value]="data.data"></lib-data-formats-tip>
      </ng-container>
    </ng-template>

    <!--subtitle-->
    <ng-template #label>
      <ng-container *ngIf="data.dataFormatType === valueDefinitionType.subtitle; else document">
        <h4 class="mat-subtitle-2">
          {{ data.data.type_details.value }}
        </h4>
      </ng-container>
    </ng-template>

    <!--Document type-->
    <ng-template #document>
      <ng-container *ngIf="data.dataFormatType === valueDefinitionType.document">
        <lib-data-formats-document [value]="data.data" [itemType]="itemType"></lib-data-formats-document>
      </ng-container>
    </ng-template>

    <!-- Conditional fields -->
    <ng-template #conditionalFields>
      <div class="formfield required ml-4">
        <div
          class="label"
          [ngClass]="{
            'color-error':
              formGroup.controls['conditionalInputControl'].touched &&
              formGroup.controls['conditionalInputControl'].invalid,
          }"
        >
          {{ "Please specify" | translate }}
        </div>
        <mat-form-field>
          <input matInput formControlName="conditionalInputControl" />
          <mat-error *ngIf="formGroup.controls['conditionalInputControl'].hasError('required')">{{
            "Required" | translate
          }}</mat-error>
        </mat-form-field>
      </div>
    </ng-template>
  </form>
</div>
