<mat-menu #menu="matMenu" (close)="resetInput()">
  <ng-template matMenuContent>
    <ng-container *ngIf="buttonProps?.title === 'Source'; else nonSourceMenu">
      <lib-source-menu-filter
        *ngIf="sourceConfiguration"
        [initialSources]="optionProps?.options ?? []"
        [selectedSource]="optionProps?.activeOption"
        [sourceConfiguration]="sourceConfiguration"
        (setItem)="optionSelected.emit($event)"
      >
      </lib-source-menu-filter>
    </ng-container>
    <ng-template #nonSourceMenu>
      <a mat-menu-item class="menu-title" disabled>{{ optionProps?.title }}</a>
      <div class="menu-search" (click)="$event.stopPropagation()" *ngIf="isSearchable()">
        <mat-icon class="icon-sm" svgIcon="search"></mat-icon>
        <input
          matInput
          type="text"
          placeholder="{{ 'Search' | translate }}"
          autocomplete="off"
          (keydown)="$event.stopPropagation()"
          [formControl]="searchInputControl"
        />
        <a class="clear-search" *ngIf="searchInputControl.value" (click)="resetInput()" data-testid="clearButton">
          <mat-icon class="icon-xs" svgIcon="close"></mat-icon>
        </a>
      </div>

      <ng-container *ngIf="optionProps && optionProps.defaultValue">
        <hr />
        <a mat-menu-item (click)="optionSelected.emit(optionProps.defaultValue)">
          {{ getDefaultValueTitle() }}
        </a>
      </ng-container>

      <button
        mat-menu-item
        *ngFor="let header of optionProps?.secondaryHeaders"
        (click)="optionSelected.emit(header)"
        [ngClass]="{ active: optionProps?.activeOption?.id === header.id }"
      >
        {{ header.title }}
      </button>

      <div
        [ngClass]="{ 'menu-items-container': filteredOptions.length > minMenuScrollItems }"
        [ngSwitch]="optionProps?.id"
      >
        <ng-content></ng-content>

        <ng-container *ngSwitchCase="standardCodeResourceType">
          <lib-filter-with-category
            [filterCategoryTranslations]="standardCodeCategoryTranslations"
            [filteredOptions]="filteredOptions"
            [filterFunction]="filterStandardCodes"
            [activeOptionId]="optionProps?.activeOption?.id"
            (optionSelected)="optionSelected.emit($event)"
          ></lib-filter-with-category>
        </ng-container>

        <ng-container *ngSwitchCase="frameworkTaxonomyResourceType">
          <lib-filter-with-category
            [filterCategoryTranslations]="frameworkTaxonomyCategoryTranslations"
            [filteredOptions]="filteredOptions"
            [filterFunction]="filterFrameworkTaxonomyCodes"
            [activeOptionId]="optionProps?.activeOption?.id"
            (optionSelected)="optionSelected.emit($event)"
          ></lib-filter-with-category>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <a
            mat-menu-item
            *ngFor="let option of filteredOptions"
            [title]="option.title"
            (click)="optionSelected.emit(option)"
            [ngClass]="{ active: optionProps?.activeOption?.id === option.id }"
            data-testid="filteredOption"
          >
            <span *ngIf="optionProps?.id !== topicResourceType">{{ option.title }}</span>
            <span
              *ngIf="optionProps?.id === topicResourceType"
              [ngClass]="{ 'pl-2': option.action === 'group', 'pl-4': option.action === 'topic' }"
              >{{ option.title }}</span
            >
          </a>
        </ng-container>
      </div>
    </ng-template>
  </ng-template>
</mat-menu>

<lib-filter-button [buttonProps]="buttonProps" [optionProps]="optionProps" [matMenuTriggerFor]="menu">
</lib-filter-button>
