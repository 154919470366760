import { Injectable } from '@angular/core';

import { cloneDeep } from 'lodash';
import { DynamicForm, DynamicFormConfig } from '../../../../../classes';
import {
  BOOLEAN_FORM_CONFIG,
  BooleanFieldFormModel,
  CALCULATED_FORM_CONFIG,
  CalculatedFieldFormModel,
  CHOICE_FORM_CONFIG,
  ChoiceFieldFormModel,
  DATE_FORM_CONFIG,
  DateFieldFormModel,
  FILE_ATTACHMENT_FORM_CONFIG,
  FILE_ATTACHMENT_FORM_V2_CONFIG,
  FileAttachmentFieldFormModel,
  FileAttachmentFieldV2FormModel,
  INSTRUCTION_FORM_CONFIG,
  InstructionFieldFormModel,
  LINKED_DOCUMENT_FORM_CONFIG,
  LinkedDocumentFieldFormModel,
  MetricStructureFieldFormModel,
  NUMBER_FORM_CONFIG,
  NumberFieldFormModel,
  RICH_TEXT_FORM_CONFIG,
  RichTextFieldFormModel,
  SUBTITLE_FORM_CONFIG,
  SubtitleFieldFormModel,
  TEXT_FORM_CONFIG,
  TextFieldFormModel,
} from './metric-structure-field-properties-form-configs';
import {
  ActionItem,
  BooleanTypeDetails,
  CalculatedTypeDetails,
  ChoiceFieldWidgetType,
  ChoiceTypeDetails,
  CONSOLIDATION_TRIGGER_AVG_OPTIONS,
  CONSOLIDATION_TRIGGER_SUM_OPTIONS,
  ConsolidationRules,
  ConsolidationTriggers,
  DateTypeDetails,
  NumberTypeDetails,
  DocumentHostEnv,
  DocumentTypeDetails,
  FileDocumentInterface,
  FileTypeDetails,
  FileTypeDetailsV2,
  Metric,
  MetricCategory,
  ResourceType,
  SubtitleTypeDetails,
  TextTypeDetails,
  TipTypeDetails,
  Unit,
  ValueDefinition,
  ValueDefinitionDisplayType,
  ValueDefinitionSize,
  ValueDefinitionType,
  ChoiceFieldSource,
} from '../../../../../models';
import { ValueDefinitionConstants } from '../../../../../constants/value-definition-constants';
import { MetricStructureStateService } from '../../../../services/metric-structure-state.service';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { TranslateService } from '../../../../../services/common/translate/translate.service';
import { SearchService } from '../../../../../search';
import { MetricStructurePropertiesValidators } from '../../validators/metric-structure-properties-validators';
import { values } from 'lodash';
import { map } from 'rxjs/operators';
import { AbstractControl, Validators } from '@angular/forms';
import { BaseMetricStructureService } from '../../../../services/base-metric-structure/base-metric-structure.service';
import { ValueDefinitionUtils } from '../../../../classes/ValueDefinitionUtils/value-definition-utils';
import { MetricStructureUtils } from '../../../../classes/MetricStructureUtils/metric-structure-utils';
import { ConsolidationUtils } from '../../../../../classes/ConsolidationUtils/consolidation-utils';
import { FeatureFlagService } from '../../../../../feature-flag';

type configModelsWithRequired =
  | ChoiceFieldFormModel
  | FileAttachmentFieldV2FormModel
  | FileAttachmentFieldFormModel
  | NumberFieldFormModel
  | BooleanFieldFormModel
  | TextFieldFormModel
  | DateFieldFormModel
  | RichTextFieldFormModel;

type EnabledFormFields = Partial<{
  [key in ValueDefinitionDisplayType]: string[];
}> & { default: string[] };

const ENABLED_FIELDS_ON_PUBLISHED_CORE_REF_V2_METRIC: EnabledFormFields = {
  default: ['label', 'displaySize', 'toggleNewLine'],
  [ValueDefinitionDisplayType.calculated]: ['decimals'],
  [ValueDefinitionDisplayType.number]: ['decimals'],
  [ValueDefinitionDisplayType.document]: ['displayName', 'document'],
  [ValueDefinitionDisplayType.choice]: ['allowAddOption', 'displayExplanation', 'explanationLabel', 'widgetType'],
  [ValueDefinitionDisplayType.file_v2]: [
    'displayUrl',
    'displayPageNumber',
    'displayFileExplanation',
    'explanationFileLabel',
    'maxFiles',
    'toggleMultipleFiles',
  ],
  [ValueDefinitionDisplayType.text_area]: ['placeholder', 'toggleTextarea'],
  [ValueDefinitionDisplayType.text_simple]: ['placeholder', 'toggleTextarea'],
  [ValueDefinitionDisplayType.tip]: ['instruction', 'instructionCard'],
};

const ENABLED_FIELDS_ON_CORE_REF_V2_METRIC_IN_PLATFORM: EnabledFormFields = {
  default: ['hint', 'toggleRequired', 'displaySize', 'toggleNewLine', 'shortLabel'],
  text_area: ['toggleTextarea'],
  text_simple: ['toggleTextarea'],
  number: ['units', 'decimals', 'consolidationRule', 'consolidationTrigger', 'bypassConsolidationLevels'],
  choice: ['choiceList', 'widgetType'],
  calculated: ['units', 'decimals'],
};

@Injectable()
export class MetricStructureFieldPropertiesFormService {
  private _dynamicFieldForm$: BehaviorSubject<DynamicForm<MetricStructureFieldFormModel> | undefined> =
    new BehaviorSubject<DynamicForm<MetricStructureFieldFormModel> | undefined>(undefined);
  readonly dynamicFieldForm$: Observable<DynamicForm<MetricStructureFieldFormModel> | undefined> =
    this._dynamicFieldForm$.asObservable();

  private _unitFamilies$: BehaviorSubject<ActionItem[]> = new BehaviorSubject([] as ActionItem[]);
  readonly unitFamilies$: Observable<ActionItem[]> = this._unitFamilies$.asObservable();

  private _unitDefaults$: BehaviorSubject<ActionItem[]> = new BehaviorSubject([] as ActionItem[]);
  readonly unitDefaults$: Observable<ActionItem[]> = this._unitDefaults$.asObservable();

  private _allUnits$: BehaviorSubject<ActionItem[]> = new BehaviorSubject([] as ActionItem[]);
  readonly allUnits$: Observable<ActionItem[]> = this._allUnits$.asObservable();

  private _customChoiceAnswers: ActionItem[] = [];
  private _initialForm?: DynamicForm<MetricStructureFieldFormModel>;
  private settingsOptionListsEnabled = false;

  public consolidationTriggerOptions: ActionItem[] = [];

  constructor(
    private readonly metricStructureService: MetricStructureStateService,
    private readonly translateService: TranslateService,
    private searchService: SearchService,
    private baseMetricStructureService: BaseMetricStructureService,
    private readonly featureFlagService: FeatureFlagService,
  ) {
    this.settingsOptionListsEnabled = this.featureFlagService.areAnyFeatureFlagsEnabled([
      'settings_option_lists_enabled',
    ]);
  }

  public get isValid(): boolean {
    return !!this._dynamicFieldForm$.getValue()?.valid;
  }

  public areConsolidationParamsChanged(): boolean {
    const areConsolidationRulesChanged =
      this._dynamicFieldForm$.getValue()?.controls.consolidationRule?.value !==
      this._initialForm?.controls.consolidationRule?.value;

    const areConsolidationTriggersChanged =
      this._dynamicFieldForm$.getValue()?.controls.consolidationTrigger?.value !==
      this._initialForm?.controls.consolidationTrigger?.value;

    return (
      areConsolidationRulesChanged ||
      areConsolidationTriggersChanged ||
      ConsolidationUtils.areBypassLevelsChanged(
        this._initialForm?.controls.bypassConsolidationLevels?.value as number[] | null,
        this._dynamicFieldForm$.getValue()?.controls.bypassConsolidationLevels?.value as number[] | null,
      )
    );
  }

  public initializeValueDefinitionForm(valueDefinition: ValueDefinition, metric?: Metric): void {
    let enabledFormFields: Partial<EnabledFormFields> = {};
    let isFormDisabled: boolean = true;
    const valueDefinitionGroup = metric?.value_definition_groups?.find(
      (valueDefinitionGroup) => valueDefinitionGroup.id === valueDefinition.value_definition_group_id,
    );
    const isInRepeatable = !!valueDefinitionGroup?.repeatable;

    if (MetricStructureUtils.isEntityFromCoreRefV2Metric(valueDefinition, metric)) {
      enabledFormFields = ENABLED_FIELDS_ON_CORE_REF_V2_METRIC_IN_PLATFORM;
    } else if (this.metricStructureService.isRefV2MetricInCoreEnv() && valueDefinition.published) {
      enabledFormFields = ENABLED_FIELDS_ON_PUBLISHED_CORE_REF_V2_METRIC;
    } else {
      isFormDisabled = false;
    }

    this.initializeForm(valueDefinition, metric, isFormDisabled, enabledFormFields, isInRepeatable);
    this.disableFieldsIfNeeded(valueDefinition);

    if ([ValueDefinitionType.number, ValueDefinitionType.calculated].includes(valueDefinition.type)) {
      this.getUnitsData().subscribe(() => {
        this.getUnitByFamily(
          (valueDefinition as ValueDefinition<NumberTypeDetails>).type_details?.family,
          valueDefinition,
        );
      });

      if (valueDefinition.type === ValueDefinitionType.number) {
        const consolidationRule = this._dynamicFieldForm$.getValue()?.propertiesControls.consolidationRule
          ?.value as ConsolidationRules;
        if (consolidationRule === ConsolidationRules.sum) {
          this.consolidationTriggerOptions = CONSOLIDATION_TRIGGER_SUM_OPTIONS;
        } else if (consolidationRule === ConsolidationRules.average) {
          this.consolidationTriggerOptions = CONSOLIDATION_TRIGGER_AVG_OPTIONS;
        }

        this.setNumberTypeValidation(valueDefinition.type);
        this.setConsolidationTrigger(!!consolidationRule);
      }
    }
  }

  public toValueDefinition(oldValueDefinition: ValueDefinition): ValueDefinition {
    const dynamicForm = this._dynamicFieldForm$.getValue();
    const valueDefinition = { ...oldValueDefinition };

    if (dynamicForm) {
      switch (ValueDefinitionUtils.getValueDefinitionFormat(valueDefinition).type) {
        case ValueDefinitionDisplayType.tip:
          const instructionFormModel: InstructionFieldFormModel = dynamicForm.toModel<InstructionFieldFormModel>();
          (valueDefinition as ValueDefinition<TipTypeDetails>).type_details = {
            ...valueDefinition.type_details,
            value: instructionFormModel.instruction,
            icon: instructionFormModel.instructionCard.id,
          } as TipTypeDetails;
          break;

        case ValueDefinitionDisplayType.subtitle:
          const subtitleFormModel: SubtitleFieldFormModel = dynamicForm.toModel<SubtitleFieldFormModel>();
          (valueDefinition as ValueDefinition<SubtitleTypeDetails>).type_details = {
            ...valueDefinition.type_details,
            value: subtitleFormModel.subtitle,
          } as SubtitleTypeDetails;
          break;

        case ValueDefinitionDisplayType.text_area:
        case ValueDefinitionDisplayType.text_simple:
          const textFormModel: TextFieldFormModel = dynamicForm.toModel<TextFieldFormModel>();
          valueDefinition.label = textFormModel.label;
          valueDefinition.short_label = textFormModel.shortLabel;
          valueDefinition.required = textFormModel.toggleRequired;
          valueDefinition.newline = textFormModel.toggleNewLine;
          (valueDefinition as ValueDefinition<TextTypeDetails>).type_details = {
            ...valueDefinition.type_details,
            textarea: textFormModel.toggleTextarea,
            placeholder: textFormModel.placeholder,
            rich_text: false,
          } as TextTypeDetails;
          valueDefinition.hint = textFormModel.hint;

          valueDefinition.validators = [];
          if (Number(textFormModel.characterLimit) && +textFormModel.characterLimit > 0) {
            valueDefinition.validators.push({
              validator_type: 'max_length',
              instructions: textFormModel.characterLimit,
              id: '',
            });
          }
          valueDefinition.size = textFormModel.displaySize;
          break;

        case ValueDefinitionDisplayType.text_rich:
          const richTextFormModel: RichTextFieldFormModel = dynamicForm.toModel<RichTextFieldFormModel>();
          valueDefinition.label = richTextFormModel.label;
          valueDefinition.short_label = richTextFormModel.shortLabel;
          valueDefinition.required = richTextFormModel.toggleRequired;
          valueDefinition.hint = richTextFormModel.hint;
          break;

        case ValueDefinitionDisplayType.date:
        case ValueDefinitionDisplayType.datetime:
          const dateFormModel: DateFieldFormModel = dynamicForm.toModel<DateFieldFormModel>();
          valueDefinition.size = dateFormModel.displaySize;
          valueDefinition.newline = dateFormModel.toggleNewLine;
          valueDefinition.label = dateFormModel.label;
          valueDefinition.short_label = dateFormModel.shortLabel;
          valueDefinition.required = dateFormModel.toggleRequired;
          valueDefinition.hint = dateFormModel.hint;
          (valueDefinition as ValueDefinition<DateTypeDetails>).type_details = {
            ...valueDefinition.type_details,
            format: dateFormModel.dateFormat,
          } as DateTypeDetails;

          valueDefinition.validators = [];
          if (dateFormModel.maxDateRange) {
            valueDefinition.validators.push({
              validator_type: 'max_date_range',
              instructions: dateFormModel.maxDateRange,
              id: '',
            });
          }
          if (dateFormModel.minDateRange) {
            valueDefinition.validators.push({
              validator_type: 'min_date_range',
              instructions: dateFormModel.minDateRange,
              id: '',
            });
          }

          break;

        case ValueDefinitionDisplayType.boolean:
          const booleanFormModel: BooleanFieldFormModel = dynamicForm.toModel<BooleanFieldFormModel>();
          valueDefinition.label = booleanFormModel.label;
          valueDefinition.short_label = booleanFormModel.shortLabel;
          valueDefinition.required = booleanFormModel.toggleRequired;
          valueDefinition.hint = booleanFormModel.hint;
          (valueDefinition as ValueDefinition<BooleanTypeDetails>).type_details = {
            ...valueDefinition.type_details,
            prompt_on_true: booleanFormModel.promptOnTrue,
            label_true: booleanFormModel.labelTrue,
            label_prompt_true: booleanFormModel.labelPromptTrue,
            on_true_text_required: booleanFormModel.requiredOnTrue,
            prompt_on_false: booleanFormModel.promptOnFalse,
            label_false: booleanFormModel.labelFalse,
            label_prompt_false: booleanFormModel.labelPromptFalse,
            on_false_text_required: booleanFormModel.requiredOnFalse,
          } as BooleanTypeDetails;
          break;

        case ValueDefinitionDisplayType.number:
          const numberFormModel: NumberFieldFormModel = dynamicForm.toModel<NumberFieldFormModel>();
          valueDefinition.type = ValueDefinitionType.number;
          valueDefinition.label = numberFormModel.label;
          valueDefinition.short_label = numberFormModel.shortLabel;
          valueDefinition.required = numberFormModel.toggleRequired;
          valueDefinition.hint = numberFormModel.hint;
          valueDefinition.consolidation_rule = numberFormModel.consolidationRule;
          valueDefinition.consolidation_trigger = numberFormModel.consolidationTrigger;
          valueDefinition.bypass_consolidation_levels = numberFormModel.bypassConsolidationLevels;

          (valueDefinition as ValueDefinition<NumberTypeDetails>).type_details = {
            family: numberFormModel.unitFamily,
            units: this.getUnitCodeFromId(numberFormModel.units),
            max_decimals: numberFormModel.decimals || 0,
          };

          valueDefinition.validators = [];
          if (numberFormModel.minimum?.length) {
            valueDefinition.validators.push({
              validator_type: 'min_val',
              instructions: numberFormModel.minimum,
              id: '',
            });
          }
          if (numberFormModel.maximum?.length) {
            valueDefinition.validators.push({
              validator_type: 'max_val',
              instructions: numberFormModel.maximum,
              id: '',
            });
          }
          valueDefinition.size = numberFormModel.displaySize;
          valueDefinition.newline = numberFormModel.toggleNewLine;
          break;

        case ValueDefinitionDisplayType.calculated:
          const calculatedFormModel: CalculatedFieldFormModel = dynamicForm.toModel<CalculatedFieldFormModel>();
          values(calculatedFormModel.variables).forEach((variable) => {
            variable.mandatory = calculatedFormModel.mandatory;
          });
          valueDefinition.label = calculatedFormModel.label;
          valueDefinition.short_label = calculatedFormModel.shortLabel;
          valueDefinition.size = calculatedFormModel.displaySize;
          valueDefinition.newline = calculatedFormModel.toggleNewLine;
          (valueDefinition as ValueDefinition<CalculatedTypeDetails>).type_details = {
            ...valueDefinition.type_details,
            family: calculatedFormModel.unitFamily,
            units: this.getUnitCodeFromId(calculatedFormModel.units),
            max_decimals: calculatedFormModel.decimals,
            formula: calculatedFormModel.formula,
            value_refs: calculatedFormModel.variables ?? {},
          } as CalculatedTypeDetails;
          break;

        case ValueDefinitionDisplayType.file_v2:
          const fileFormModelV2: FileAttachmentFieldV2FormModel = dynamicForm.toModel<FileAttachmentFieldV2FormModel>();
          valueDefinition.label = fileFormModelV2.label;
          valueDefinition.short_label = fileFormModelV2.shortLabel;
          valueDefinition.required = fileFormModelV2.toggleRequired;
          valueDefinition.hint = fileFormModelV2.hint;
          (valueDefinition as ValueDefinition<FileTypeDetailsV2>).type_details = {
            ...valueDefinition.type_details,
            multiple: fileFormModelV2.toggleMultipleFiles,
            max_files: fileFormModelV2.toggleMultipleFiles ? fileFormModelV2.maxFiles : 1,
            attachment_optional: fileFormModelV2.attachmentOptional,
            attachment_enabled: fileFormModelV2.attachmentEnabled,
            display_url: fileFormModelV2.displayUrl,
            url_required: fileFormModelV2.displayUrl ? fileFormModelV2.urlRequired : false,
            display_page_number: fileFormModelV2.displayPageNumber,
            page_number_required: fileFormModelV2.displayPageNumber ? fileFormModelV2.pageNumberRequired : false,
            display_explanation: fileFormModelV2.displayFileExplanation,
            explanation_required: fileFormModelV2.displayFileExplanation
              ? fileFormModelV2.explanationFileRequired
              : false,
            explanation_label:
              fileFormModelV2.displayFileExplanation &&
              fileFormModelV2.explanationFileLabel &&
              fileFormModelV2.explanationFileLabel.trim().length > 0
                ? fileFormModelV2.explanationFileLabel
                : this.translateService.instant(ValueDefinitionConstants.DEFAULT_EXPLANATION_LABEL),
          } as FileTypeDetailsV2;
          break;

        case ValueDefinitionDisplayType.document:
          const documentFormModel: LinkedDocumentFieldFormModel = dynamicForm.toModel<LinkedDocumentFieldFormModel>();
          valueDefinition.document = documentFormModel.document;
          valueDefinition.newline = documentFormModel.toggleNewLine;
          (valueDefinition as ValueDefinition<DocumentTypeDetails>).type_details = {
            ...valueDefinition.type_details,
            document_id: documentFormModel.document.id,
            display_name: documentFormModel.displayName,
            document_host_env: this.getDocumentHostEnv(valueDefinition, documentFormModel.document.id),
          } as DocumentTypeDetails;
          break;

        case ValueDefinitionDisplayType.choice:
        case ValueDefinitionDisplayType.choice_multiple:
        case ValueDefinitionDisplayType.choice_radio:
        case ValueDefinitionDisplayType.choice_searchable:
          const choiceFormModel: ChoiceFieldFormModel = dynamicForm.toModel<ChoiceFieldFormModel>();
          valueDefinition.label = choiceFormModel.label;
          valueDefinition.short_label = choiceFormModel.shortLabel;
          valueDefinition.required = choiceFormModel.toggleRequired;
          (valueDefinition as ValueDefinition<ChoiceTypeDetails>).type_details = {
            ...valueDefinition.type_details,
            multi_choices: [ChoiceFieldWidgetType.checkboxes, ChoiceFieldWidgetType.multi_chip].includes(
              choiceFormModel.widgetType,
            ),
            widget_type: choiceFormModel.widgetType,
            choices: null,
            selection_set_id:
              choiceFormModel.choiceList === 'custom' ? choiceFormModel.optionList : choiceFormModel.choiceList,
            selection_set_category_id:
              this.metricStructureService.isAdmin || valueDefinition.type_details?.selection_set_category_id
                ? choiceFormModel.choiceListCategory
                : null,
            selection_set_source: this.metricStructureService.isAdmin
              ? null
              : choiceFormModel.choiceList === 'custom'
                ? ChoiceFieldSource.platform
                : choiceFormModel.choiceListSource,
            selection_set_apply_all: choiceFormModel.choiceListApplyAll,
            display_explanation: choiceFormModel.displayExplanation,
            explanation_required: choiceFormModel.displayExplanation ? choiceFormModel.explanationRequired : false,
            explanation_label:
              choiceFormModel.displayExplanation &&
              choiceFormModel.explanationLabel &&
              choiceFormModel.explanationLabel.trim().length > 0
                ? choiceFormModel.explanationLabel
                : '',
            allow_add_option: choiceFormModel.allowAddOption,
          } as ChoiceTypeDetails;
          valueDefinition.hint = choiceFormModel.hint;
          valueDefinition.size = choiceFormModel.displaySize;
          valueDefinition.newline = choiceFormModel.toggleNewLine;
          break;

        default:
          break;
      }
    }

    return valueDefinition;
  }

  private getDocumentHostEnv(
    valueDefinition: ValueDefinition<DocumentTypeDetails>,
    linkedDocumentId: string,
  ): DocumentHostEnv {
    if (this.baseMetricStructureService.documentLinkHostEnvironment === 'core') {
      return 'core';
    } else {
      if (
        valueDefinition.type_details?.document_host_env === 'core' &&
        linkedDocumentId === valueDefinition.type_details.document_id
      ) {
        return 'core';
      }
      return 'platform';
    }
  }

  private initializeForm(
    valueDefinition: ValueDefinition,
    metric?: Metric,
    isFormDisabled: boolean = false,
    enabledFormFields: Partial<EnabledFormFields> = {},
    isInRepeatable: boolean = false,
  ): void {
    let dynamicFieldForm: DynamicForm<MetricStructureFieldFormModel> | undefined = undefined;

    const defaultEnabledFormFields: string[] = enabledFormFields.default || [];

    switch (ValueDefinitionUtils.getValueDefinitionFormat(valueDefinition).type) {
      case ValueDefinitionDisplayType.tip:
        dynamicFieldForm = this.getTipDynamicForm(
          valueDefinition as ValueDefinition<TipTypeDetails>,
          isFormDisabled,
          defaultEnabledFormFields.concat(enabledFormFields[ValueDefinitionDisplayType.tip] || []),
        );
        break;

      case ValueDefinitionDisplayType.subtitle:
        dynamicFieldForm = this.getSubtitleDynamicForm(
          valueDefinition as ValueDefinition<SubtitleTypeDetails>,
          isFormDisabled,
          defaultEnabledFormFields.concat(enabledFormFields[ValueDefinitionDisplayType.subtitle] || []),
        );
        break;

      case ValueDefinitionDisplayType.text_area:
      case ValueDefinitionDisplayType.text_simple:
        dynamicFieldForm = this.getTextDynamicForm(
          valueDefinition as ValueDefinition<TextTypeDetails>,
          isFormDisabled,
          [
            ...new Set(
              defaultEnabledFormFields.concat(
                enabledFormFields[ValueDefinitionDisplayType.text_area] || [],
                enabledFormFields[ValueDefinitionDisplayType.text_simple] || [],
              ),
            ),
          ],
        );
        break;

      case ValueDefinitionDisplayType.text_rich:
        dynamicFieldForm = this.getRichTextDynamicForm(
          valueDefinition as ValueDefinition<TextTypeDetails>,
          isFormDisabled,
          defaultEnabledFormFields.concat(enabledFormFields[ValueDefinitionDisplayType.text_rich] || []),
        );
        break;

      case ValueDefinitionDisplayType.date:
      case ValueDefinitionDisplayType.datetime:
        dynamicFieldForm = this.getDateDynamicForm(
          valueDefinition as ValueDefinition<DateTypeDetails>,
          isFormDisabled,
          [
            ...new Set(
              defaultEnabledFormFields.concat(
                enabledFormFields[ValueDefinitionDisplayType.date] || [],
                enabledFormFields[ValueDefinitionDisplayType.datetime] || [],
              ),
            ),
          ],
        );
        break;

      case ValueDefinitionDisplayType.boolean:
        dynamicFieldForm = this.getBooleanDynamicForm(
          valueDefinition as ValueDefinition<BooleanTypeDetails>,
          isFormDisabled,
          defaultEnabledFormFields.concat(enabledFormFields[ValueDefinitionDisplayType.boolean] || []),
        );
        break;

      case ValueDefinitionDisplayType.number:
        dynamicFieldForm = this.getNumberDynamicForm(
          valueDefinition as ValueDefinition<NumberTypeDetails>,
          isFormDisabled,
          defaultEnabledFormFields.concat(enabledFormFields[ValueDefinitionDisplayType.number] || []),
          isInRepeatable,
        );
        break;

      case ValueDefinitionDisplayType.calculated:
        dynamicFieldForm = this.getCalculatedDynamicForm(
          valueDefinition as ValueDefinition<CalculatedTypeDetails>,
          isFormDisabled,
          defaultEnabledFormFields.concat(enabledFormFields[ValueDefinitionDisplayType.calculated] || []),
        );
        break;

      case ValueDefinitionDisplayType.file_v2:
        dynamicFieldForm = this.getFileV2DynamicForm(
          valueDefinition as ValueDefinition<FileTypeDetailsV2>,
          isFormDisabled,
          defaultEnabledFormFields.concat(enabledFormFields[ValueDefinitionDisplayType.file_v2] || []),
        );
        break;

      case ValueDefinitionDisplayType.document:
        dynamicFieldForm = this.getDocumentDynamicForm(
          valueDefinition as ValueDefinition<DocumentTypeDetails>,
          isFormDisabled,
          defaultEnabledFormFields.concat(enabledFormFields[ValueDefinitionDisplayType.document] || []),
        );
        break;

      case ValueDefinitionDisplayType.choice:
        dynamicFieldForm = this.getChoiceDynamicForm(
          valueDefinition as ValueDefinition<ChoiceTypeDetails>,
          isFormDisabled,
          defaultEnabledFormFields.concat(enabledFormFields[ValueDefinitionDisplayType.choice] || []),
          metric,
        );
        break;

      default:
        break;
    }

    this._dynamicFieldForm$.next(dynamicFieldForm);
    this._initialForm = cloneDeep(dynamicFieldForm);
  }

  private getTipDynamicForm(
    valueDefinition: ValueDefinition<TipTypeDetails>,
    isFormDisabled: boolean,
    enabledFormFields: string[],
  ): DynamicForm<InstructionFieldFormModel> {
    const displayOption = this.metricStructureService.tipDisplayOptions.find(
      (displayOption) => displayOption.id === valueDefinition.type_details?.icon,
    );
    const form = new DynamicForm<InstructionFieldFormModel>(
      INSTRUCTION_FORM_CONFIG,
      {
        instruction: valueDefinition.type_details?.value,
        instructionCard: displayOption,
      },
      isFormDisabled,
      enabledFormFields,
    );
    return form;
  }

  private getSubtitleDynamicForm(
    valueDefinition: ValueDefinition<SubtitleTypeDetails>,
    isFormDisabled: boolean,
    enabledFormFields: string[],
  ): DynamicForm<SubtitleFieldFormModel> {
    return new DynamicForm<SubtitleFieldFormModel>(
      SUBTITLE_FORM_CONFIG,
      {
        subtitle: valueDefinition.type_details?.value,
      },
      isFormDisabled,
      enabledFormFields,
    );
  }

  private getTextDynamicForm(
    valueDefinition: ValueDefinition<TextTypeDetails>,
    isFormDisabled: boolean,
    enabledFormFields: string[],
  ): DynamicForm<TextFieldFormModel> {
    return new DynamicForm<TextFieldFormModel>(
      this.getFieldConfigAfterRefV2InCoreCheck({ ...TEXT_FORM_CONFIG }),
      {
        label: valueDefinition.label,
        shortLabel: valueDefinition.short_label,
        toggleRequired: valueDefinition.required,
        toggleTextarea: !!valueDefinition.type_details?.textarea,
        placeholder: valueDefinition.type_details?.placeholder,
        hint: valueDefinition.hint,
        characterLimit:
          valueDefinition.validators?.find((validator) => validator.validator_type === 'max_length')?.instructions ??
          (valueDefinition.label ? '0' : ''),
        displaySize: valueDefinition.type_details?.textarea ? ValueDefinitionSize.large : valueDefinition.size,
        toggleNewLine: valueDefinition.type_details?.textarea || valueDefinition.newline,
      },
      isFormDisabled,
      enabledFormFields,
    );
  }

  private getRichTextDynamicForm(
    valueDefinition: ValueDefinition<TextTypeDetails>,
    isFormDisabled: boolean,
    enabledFormFields: string[],
  ): DynamicForm<RichTextFieldFormModel> {
    return new DynamicForm<RichTextFieldFormModel>(
      this.getFieldConfigAfterRefV2InCoreCheck({ ...RICH_TEXT_FORM_CONFIG }),
      {
        label: valueDefinition.label,
        shortLabel: valueDefinition.short_label,
        toggleRequired: valueDefinition.required,
        hint: valueDefinition.hint,
      },
      isFormDisabled,
      enabledFormFields,
    );
  }

  private getDateDynamicForm(
    valueDefinition: ValueDefinition<DateTypeDetails>,
    isFormDisabled: boolean,
    enabledFormFields: string[],
  ): DynamicForm<DateFieldFormModel> {
    const dynamicFieldForm = new DynamicForm<DateFieldFormModel>(
      this.getFieldConfigAfterRefV2InCoreCheck({ ...DATE_FORM_CONFIG }),
      {
        label: valueDefinition.label,
        shortLabel: valueDefinition.short_label,
        toggleRequired: valueDefinition.required,
        hint: valueDefinition.hint,
        dateFormat: valueDefinition.type_details?.format,
        minDateRange: valueDefinition.validators?.find((x) => x.validator_type === 'min_date_range')?.instructions,
        maxDateRange: valueDefinition.validators?.find((x) => x.validator_type === 'max_date_range')?.instructions,
        displaySize: valueDefinition.size,
        toggleNewLine: valueDefinition.newline,
      },
      isFormDisabled,
      enabledFormFields,
    );
    dynamicFieldForm.setValidators(MetricStructurePropertiesValidators.dateRangeValidator);
    return dynamicFieldForm;
  }

  private getBooleanDynamicForm(
    valueDefinition: ValueDefinition<BooleanTypeDetails>,
    isFormDisabled: boolean,
    enabledFormFields: string[],
  ): DynamicForm<BooleanFieldFormModel> {
    const config: DynamicFormConfig<Partial<BooleanFieldFormModel>> = this.getFieldConfigAfterRefV2InCoreCheck({
      ...BOOLEAN_FORM_CONFIG,
    });
    if (this.metricStructureService.isRefV2MetricInCoreEnv()) {
      delete config.requiredOnTrue;
      delete config.requiredOnFalse;
    }
    return new DynamicForm<BooleanFieldFormModel>(
      config,
      {
        label: valueDefinition.label,
        shortLabel: valueDefinition.short_label,
        toggleRequired: valueDefinition.required,
        hint: valueDefinition.hint,
        promptOnTrue: valueDefinition.type_details?.prompt_on_true,
        promptOnFalse: valueDefinition.type_details?.prompt_on_false,
        labelTrue: valueDefinition.type_details?.label_true,
        labelFalse: valueDefinition.type_details?.label_false,
        labelPromptTrue: valueDefinition.type_details?.label_prompt_true,
        labelPromptFalse: valueDefinition.type_details?.label_prompt_false,
        requiredOnTrue: valueDefinition.type_details?.on_true_text_required,
        requiredOnFalse: valueDefinition.type_details?.on_false_text_required,
        displaySize: ValueDefinitionSize.large,
        toggleNewLine: true,
      },
      isFormDisabled,
      enabledFormFields,
    );
  }

  private getNumberDynamicForm(
    valueDefinition: ValueDefinition<NumberTypeDetails>,
    isFormDisabled: boolean,
    enabledFormFields: string[],
    isInRepeatable: boolean,
  ): DynamicForm<NumberFieldFormModel> {
    let defaultconsolidationRule = valueDefinition.consolidation_rule ?? ConsolidationRules.sum;
    let defaultconsolidationTrigger: ConsolidationTriggers | null =
      valueDefinition.consolidation_trigger === undefined
        ? ConsolidationTriggers.update_when_one_value
        : valueDefinition.consolidation_trigger;
    if (isInRepeatable) {
      defaultconsolidationRule = ConsolidationRules.manual;
      defaultconsolidationTrigger = null;
    }
    const dynamicFieldForm = new DynamicForm<NumberFieldFormModel>(
      this.getFieldConfigAfterRefV2InCoreCheck({ ...NUMBER_FORM_CONFIG }),
      {
        label: valueDefinition.label,
        shortLabel: valueDefinition.short_label,
        toggleRequired: valueDefinition.required,
        hint: valueDefinition.hint,
        unitFamily: valueDefinition.type_details?.family,
        units: valueDefinition.type_details?.units,
        decimals:
          valueDefinition.type_details?.max_decimals != null ? `${valueDefinition.type_details.max_decimals}` : null,
        minimum: valueDefinition.validators?.find((x) => x.validator_type === 'min_val')?.instructions,
        maximum: valueDefinition.validators?.find((x) => x.validator_type === 'max_val')?.instructions,
        type: valueDefinition.type,
        displaySize: valueDefinition.size,
        toggleNewLine: valueDefinition.newline,
        consolidationRule: defaultconsolidationRule,
        consolidationTrigger:
          valueDefinition.consolidation_rule === ConsolidationRules.manual ? null : defaultconsolidationTrigger,
        bypassConsolidationLevels: valueDefinition.bypass_consolidation_levels?.map(String),
      },
      isFormDisabled,
      enabledFormFields,
    );
    dynamicFieldForm.setValidators(MetricStructurePropertiesValidators.isMaxBiggerThanMin);
    dynamicFieldForm.propertiesControls.consolidationTrigger?.disable();
    return dynamicFieldForm;
  }

  private getCalculatedDynamicForm(
    valueDefinition: ValueDefinition<CalculatedTypeDetails>,
    isFormDisabled: boolean,
    enabledFormFields: string[],
  ): DynamicForm<CalculatedFieldFormModel> {
    return new DynamicForm<CalculatedFieldFormModel>(
      CALCULATED_FORM_CONFIG,
      {
        label: valueDefinition.label,
        shortLabel: valueDefinition.short_label,
        unitFamily: valueDefinition.type_details?.family,
        units: valueDefinition.type_details?.units,
        decimals:
          valueDefinition.type_details?.max_decimals != null ? `${valueDefinition.type_details.max_decimals}` : null,
        formula: valueDefinition.type_details?.formula,
        displaySize: valueDefinition.size,
        toggleNewLine: valueDefinition.newline,
        variables: valueDefinition.type_details?.value_refs,
        mandatory: values(valueDefinition.type_details?.value_refs)[0]?.mandatory || false,
      },
      isFormDisabled,
      enabledFormFields,
    );
  }

  private getFileDynamicForm(
    valueDefinition: ValueDefinition<FileTypeDetails>,
    isFormDisabled: boolean,
    enabledFormFields: string[],
  ): DynamicForm<FileAttachmentFieldFormModel> {
    const dynamicFieldForm = new DynamicForm<FileAttachmentFieldFormModel>(
      this.getFieldConfigAfterRefV2InCoreCheck({ ...FILE_ATTACHMENT_FORM_CONFIG }),
      {
        label: valueDefinition.label,
        shortLabel: valueDefinition.short_label,
        toggleRequired: valueDefinition.required,
        toggleMultipleFiles: !!valueDefinition.type_details?.multiple,
        maxFiles: valueDefinition.type_details?.multiple ? valueDefinition.type_details.max_files : null,
        hint: valueDefinition.hint,
        fileSize: 150, //hard coded at 150mb for now
      },
      isFormDisabled,
      enabledFormFields,
    );
    dynamicFieldForm.propertiesControls.fileSize?.disable();
    this.setIsMultipleFiles(!!valueDefinition.type_details?.multiple, dynamicFieldForm);
    return dynamicFieldForm;
  }

  private getFileV2DynamicForm(
    valueDefinition: ValueDefinition<FileTypeDetailsV2>,
    isFormDisabled: boolean,
    enabledFormFields: string[],
  ): DynamicForm<FileAttachmentFieldV2FormModel> {
    const disabledFormFields: string[] = [];
    const config: DynamicFormConfig<Partial<FileAttachmentFieldV2FormModel>> = this.getFieldConfigAfterRefV2InCoreCheck(
      { ...FILE_ATTACHMENT_FORM_V2_CONFIG },
    );
    if (this.metricStructureService.isRefV2MetricInCoreEnv()) {
      delete config.explanationFileRequired;
      delete config.urlRequired;
      delete config.pageNumberRequired;

      if (valueDefinition.published) {
        if (valueDefinition.type_details?.display_explanation) {
          disabledFormFields.push('displayFileExplanation');
        }

        if (valueDefinition.type_details?.display_page_number) {
          disabledFormFields.push('displayPageNumber');
        }

        if (valueDefinition.type_details?.display_url) {
          disabledFormFields.push('displayUrl');
        }

        if (valueDefinition.type_details?.max_files) {
          const currentMaxFiles = valueDefinition.type_details.max_files;
          config.maxFiles = {
            validators: [
              ...(FILE_ATTACHMENT_FORM_V2_CONFIG.maxFiles?.validators || []),
              (control) => (control.value < currentMaxFiles ? { minMaxFiles: true } : null),
            ],
          };
        }

        if (valueDefinition.type_details?.multiple) {
          disabledFormFields.push('toggleMultipleFiles');
        }
      }
    }

    const dynamicFieldForm = new DynamicForm<FileAttachmentFieldV2FormModel>(
      config,
      {
        label: valueDefinition.label,
        shortLabel: valueDefinition.short_label,
        toggleRequired: valueDefinition.required,
        toggleMultipleFiles: !!valueDefinition.type_details?.multiple,
        maxFiles: valueDefinition.type_details?.multiple ? valueDefinition.type_details.max_files : null,
        hint: valueDefinition.hint,
        attachmentOptional: valueDefinition.type_details?.attachment_optional,
        attachmentEnabled: valueDefinition.type_details?.attachment_optional
          ? valueDefinition.type_details?.attachment_enabled
          : true,
        displayUrl: valueDefinition.type_details?.attachment_optional
          ? true
          : valueDefinition.type_details?.display_url,
        urlRequired: valueDefinition.type_details?.display_url ? valueDefinition.type_details?.url_required : false,
        displayPageNumber: !!valueDefinition.type_details?.display_page_number,
        pageNumberRequired: valueDefinition.type_details?.display_page_number
          ? valueDefinition.type_details?.page_number_required
          : false,
        displayFileExplanation: !!valueDefinition.type_details?.display_explanation,
        explanationFileRequired: valueDefinition.type_details?.display_explanation
          ? valueDefinition.type_details?.explanation_required
          : false,
        explanationFileLabel:
          valueDefinition.type_details?.display_explanation &&
          valueDefinition.type_details?.explanation_label &&
          valueDefinition.type_details?.explanation_label.trim().length > 0
            ? valueDefinition.type_details?.explanation_label
            : this.translateService.instant(ValueDefinitionConstants.DEFAULT_EXPLANATION_LABEL),
        fileSize: 150, //hard coded at 150mb for now
      },
      isFormDisabled,
      enabledFormFields.filter((f) => !disabledFormFields.includes(f)),
    );
    dynamicFieldForm.propertiesControls.fileSize?.disable();
    if (valueDefinition.type_details?.attachment_optional) {
      dynamicFieldForm?.propertiesControls?.displayUrl?.disable();
      if (!valueDefinition.type_details.attachment_enabled) {
        dynamicFieldForm?.propertiesControls?.urlRequired?.disable();
      }
    }
    this.setIsMultipleFiles(!!valueDefinition.type_details?.multiple, dynamicFieldForm);

    return dynamicFieldForm;
  }

  public handleAllowUrlAsFile(): void {
    const dynamicFieldForm = this._dynamicFieldForm$.getValue();

    if (
      !dynamicFieldForm?.propertiesControls.displayUrl ||
      !dynamicFieldForm?.propertiesControls.attachmentOptional ||
      !dynamicFieldForm?.propertiesControls.attachmentEnabled
    ) {
      return;
    }

    if (dynamicFieldForm?.propertiesControls.attachmentOptional.value) {
      dynamicFieldForm?.propertiesControls.displayUrl.disable();
    } else {
      dynamicFieldForm?.propertiesControls.attachmentEnabled.setValue(true);
      dynamicFieldForm?.propertiesControls?.displayUrl.enable();
      dynamicFieldForm?.propertiesControls?.urlRequired?.enable();
      dynamicFieldForm?.propertiesControls?.urlRequired?.setValue(false);
    }
  }

  public handleAllowAttachingDocument(): void {
    const dynamicFieldForm = this._dynamicFieldForm$.getValue();

    if (
      !dynamicFieldForm?.propertiesControls.displayUrl ||
      !dynamicFieldForm?.propertiesControls.attachmentEnabled ||
      !dynamicFieldForm?.propertiesControls?.urlRequired
    ) {
      return;
    }

    if (!dynamicFieldForm?.propertiesControls.attachmentEnabled.value) {
      dynamicFieldForm?.propertiesControls?.urlRequired.setValue(true);
      dynamicFieldForm?.propertiesControls?.urlRequired.disable();
    } else {
      dynamicFieldForm?.propertiesControls?.urlRequired.enable();
      dynamicFieldForm?.propertiesControls?.urlRequired.setValue(false);
    }
  }

  private getDocumentDynamicForm(
    valueDefinition: ValueDefinition<DocumentTypeDetails>,
    isFormDisabled: boolean,
    enabledFormFields: string[],
  ): DynamicForm<LinkedDocumentFieldFormModel> {
    let doc: FileDocumentInterface | undefined;
    if (valueDefinition.type_details?.document_id) {
      doc = this.metricStructureService.getDocumentById(valueDefinition.type_details.document_id);
    }

    return new DynamicForm<LinkedDocumentFieldFormModel>(
      LINKED_DOCUMENT_FORM_CONFIG,
      {
        document: doc,
        displayName: valueDefinition.type_details?.display_name,
        toggleNewLine: valueDefinition.newline,
      },
      isFormDisabled,
      enabledFormFields,
    );
  }

  private getChoiceDynamicForm(
    valueDefinition: ValueDefinition<ChoiceTypeDetails>,
    isFormDisabled: boolean,
    enabledFormFields: string[],
    metric?: Metric,
  ): DynamicForm<ChoiceFieldFormModel> {
    const disabledFormFields: string[] = [];
    const alwaysDisabledFields: (keyof ChoiceFieldFormModel)[] = [];
    const config: DynamicFormConfig<Partial<ChoiceFieldFormModel>> = this.getFieldConfigAfterRefV2InCoreCheck({
      ...CHOICE_FORM_CONFIG,
    });

    if (this.metricStructureService.isRefV2MetricInCoreEnv()) {
      delete config.explanationRequired;

      if (valueDefinition.published) {
        if (valueDefinition.type_details?.display_explanation) {
          disabledFormFields.push('displayExplanation');
        }

        if (valueDefinition.type_details?.allow_add_option) {
          disabledFormFields.push('allowAddOption');
        }

        if (valueDefinition.type_details?.widget_type) {
          const allowAddOption = valueDefinition.type_details?.allow_add_option;
          const currentWidgetType = valueDefinition.type_details.widget_type;
          if (
            currentWidgetType === ChoiceFieldWidgetType.checkboxes ||
            (allowAddOption && currentWidgetType === ChoiceFieldWidgetType.multi_chip)
          ) {
            disabledFormFields.push('widgetType');
          } else {
            config.widgetType = {
              validators: [
                ...(CHOICE_FORM_CONFIG.widgetType?.validators || []),
                (control: AbstractControl<ChoiceFieldWidgetType>) => {
                  let invalid = false;

                  if (control.value !== currentWidgetType) {
                    if (
                      [ChoiceFieldWidgetType.multi_chip, ChoiceFieldWidgetType.radio_buttons].includes(
                        currentWidgetType,
                      ) &&
                      control.value !== ChoiceFieldWidgetType.checkboxes
                    ) {
                      invalid = true;
                    } else if (
                      currentWidgetType === ChoiceFieldWidgetType.single_select &&
                      allowAddOption &&
                      [ChoiceFieldWidgetType.checkboxes, ChoiceFieldWidgetType.radio_buttons].includes(control.value)
                    ) {
                      invalid = true;
                    }
                  }

                  return invalid ? { invalidWidgetType: true } : null;
                },
              ],
            };
          }
        }
      }
    }

    if (metric?.category === MetricCategory.REFERENCE) {
      if (this.metricStructureService.isAdmin) {
        config.choiceList = {};
      } else if (this.settingsOptionListsEnabled) {
        if (valueDefinition.type_details?.selection_set_category_id) {
          alwaysDisabledFields.push('choiceListCategory');
        } else if (
          valueDefinition.type_details?.selection_set_id &&
          valueDefinition.type_details?.selection_set_source === ChoiceFieldSource.core
        ) {
          alwaysDisabledFields.push('choiceListCategory', 'choiceList');
        }
      }
    } else if (metric?.category === MetricCategory.THIRD_PARTY) {
      alwaysDisabledFields.push('displayExplanation', 'explanationRequired');
    }

    if (!this.metricStructureService.isAdmin && !this.settingsOptionListsEnabled) {
      config.choiceListCategory = {};
    }

    return new DynamicForm<ChoiceFieldFormModel>(
      config,
      {
        label: valueDefinition.label,
        shortLabel: valueDefinition.short_label,
        toggleRequired: valueDefinition.required,
        toggleMultipleSelection: valueDefinition.type_details?.multi_choices,
        hint: valueDefinition.hint,
        widgetType: valueDefinition.type_details?.widget_type,
        displayExplanation:
          metric?.category === MetricCategory.THIRD_PARTY ? true : valueDefinition.type_details?.display_explanation,
        explanationLabel: valueDefinition.type_details?.explanation_label
          ? valueDefinition.type_details.explanation_label
          : this.translateService.instant('Please explain'),
        explanationRequired:
          metric?.category === MetricCategory.THIRD_PARTY
            ? false
            : valueDefinition.type_details?.display_explanation
              ? valueDefinition.type_details.explanation_required
              : false,
        optionList: valueDefinition.type_details?.selection_set_id,
        choiceList:
          valueDefinition.type_details?.selection_set_source === ChoiceFieldSource.platform &&
          !this.settingsOptionListsEnabled
            ? 'custom'
            : valueDefinition.type_details?.selection_set_id || null,
        choiceListCategory: valueDefinition.type_details?.selection_set_category_id || null,
        choiceListApplyAll: valueDefinition.type_details?.selection_set_apply_all ?? true,
        choiceListSource:
          valueDefinition.type_details?.selection_set_source ||
          (this.metricStructureService.isAdmin
            ? null
            : !this.settingsOptionListsEnabled ||
                (metric?.category === MetricCategory.REFERENCE &&
                  valueDefinition.type_details?.selection_set_source === ChoiceFieldSource.core &&
                  !valueDefinition.type_details?.selection_set_category_id)
              ? ChoiceFieldSource.core
              : ChoiceFieldSource.platform),
        allowAddOption:
          valueDefinition.type_details?.widget_type === ChoiceFieldWidgetType.radio_buttons ||
          valueDefinition.type_details?.widget_type === ChoiceFieldWidgetType.checkboxes
            ? false
            : valueDefinition.type_details?.allow_add_option,
        displaySize:
          valueDefinition.type_details?.widget_type === ChoiceFieldWidgetType.multi_chip
            ? 'large'
            : valueDefinition.size,
        toggleNewLine:
          valueDefinition.type_details?.display_explanation ||
          valueDefinition.type_details?.widget_type === ChoiceFieldWidgetType.multi_chip ||
          valueDefinition.newline,
      },
      isFormDisabled,
      enabledFormFields.filter((f) => !disabledFormFields.includes(f)),
      alwaysDisabledFields,
    );
  }

  private getFieldConfigAfterRefV2InCoreCheck(
    config: DynamicFormConfig<Partial<configModelsWithRequired>>,
  ): DynamicFormConfig<Partial<configModelsWithRequired>> {
    const updatedConfig = { ...config };
    if (this.metricStructureService.isRefV2MetricInCoreEnv()) {
      delete updatedConfig.toggleRequired;
    }
    return updatedConfig;
  }

  private disableFieldsIfNeeded(valueDefinition: ValueDefinition): void {
    if (
      (valueDefinition as ValueDefinition<TextTypeDetails>).type_details?.textarea ||
      (valueDefinition as ValueDefinition<BooleanTypeDetails>).type_details?.prompt_on_true ||
      (valueDefinition as ValueDefinition<BooleanTypeDetails>).type_details?.prompt_on_false ||
      (valueDefinition as ValueDefinition<ChoiceTypeDetails>).type_details?.display_explanation ||
      (valueDefinition as ValueDefinition<ChoiceTypeDetails>).type_details?.widget_type ===
        ChoiceFieldWidgetType.multi_chip
    ) {
      this.allowFieldOnMultipleLine = true;
    }
    this.toggleAllowAddOption(valueDefinition);
  }

  public toggleMultipleLine() {
    const dynamicFieldForm = this._dynamicFieldForm$.getValue();
    const isMultiple = !!(
      dynamicFieldForm?.propertiesControls.toggleTextarea?.value ||
      dynamicFieldForm?.propertiesControls.promptOnTrue?.value ||
      dynamicFieldForm?.propertiesControls.promptOnFalse?.value ||
      dynamicFieldForm?.propertiesControls.displayExplanation?.value ||
      dynamicFieldForm?.propertiesControls.widgetType?.value === ChoiceFieldWidgetType.multi_chip ||
      dynamicFieldForm?.propertiesControls.displayUrl?.value ||
      dynamicFieldForm?.propertiesControls.displayPageNumber?.value ||
      dynamicFieldForm?.propertiesControls.displayFileExplanation?.value
    );
    this.allowFieldOnMultipleLine = isMultiple;
  }

  public toggleAllowAddOption(valueDefinition?: ValueDefinition) {
    const dynamicFieldForm = this._dynamicFieldForm$.getValue();
    if (!dynamicFieldForm?.propertiesControls.allowAddOption) {
      return;
    }

    if (
      [ChoiceFieldWidgetType.checkboxes, ChoiceFieldWidgetType.radio_buttons].includes(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        dynamicFieldForm.propertiesControls.widgetType?.value,
      )
    ) {
      dynamicFieldForm.propertiesControls.allowAddOption.setValue(false);
      dynamicFieldForm.propertiesControls.allowAddOption.disable();
    } else if (!valueDefinition?.published) {
      dynamicFieldForm.propertiesControls.allowAddOption.enable();
    }
  }

  public toggleExplanationLabelValidator(displayExplanation: boolean) {
    const dynamicFieldForm = this._dynamicFieldForm$.getValue();

    if (!dynamicFieldForm?.propertiesControls.explanationLabel) {
      return;
    }

    dynamicFieldForm.propertiesControls.explanationLabel.setValidators(displayExplanation ? [Validators.required] : []);
    dynamicFieldForm.propertiesControls.explanationLabel.updateValueAndValidity();
  }

  public set allowFieldOnMultipleLine(isMultiple: boolean) {
    const dynamicFieldForm = this._dynamicFieldForm$.getValue();
    if (isMultiple) {
      dynamicFieldForm?.propertiesControls.displaySize?.setValue('large');
      dynamicFieldForm?.propertiesControls.displaySize?.disable();
      dynamicFieldForm?.propertiesControls.toggleNewLine?.setValue(true);
      dynamicFieldForm?.propertiesControls.toggleNewLine?.disable();
    } else {
      dynamicFieldForm?.propertiesControls.displaySize?.enable();
      dynamicFieldForm?.propertiesControls.toggleNewLine?.setValue(false);
      dynamicFieldForm?.propertiesControls.toggleNewLine?.enable();
    }

    this._dynamicFieldForm$.next(dynamicFieldForm);
  }

  public setNumberTypeValidation(numberType: string): void {
    const dynamicFieldForm = this._dynamicFieldForm$.getValue();

    if (numberType === ValueDefinitionType.number.toString()) {
      dynamicFieldForm?.controls.decimals.enable();
    } else {
      dynamicFieldForm?.controls.decimals.disable();
    }

    this._dynamicFieldForm$.next(dynamicFieldForm);
  }

  public setIsMultipleFiles(
    isMultipleFiles: boolean,
    dynamicFieldForm?: DynamicForm<MetricStructureFieldFormModel>,
  ): void {
    dynamicFieldForm = dynamicFieldForm ? dynamicFieldForm : this._dynamicFieldForm$.getValue();

    if (isMultipleFiles) {
      dynamicFieldForm?.controls.maxFiles.enable();
    } else {
      dynamicFieldForm?.controls.maxFiles.disable();
    }

    this._dynamicFieldForm$.next(dynamicFieldForm);
  }

  public set tipDisplayOption(item: ActionItem) {
    const dynamicFieldForm = this._dynamicFieldForm$.getValue();

    dynamicFieldForm?.propertiesControls.instructionCard?.setValue(item);
    dynamicFieldForm?.propertiesControls.instructionCard?.markAsDirty();

    this._dynamicFieldForm$.next(dynamicFieldForm);
  }

  public set numberUnit(numberUnit: string) {
    const dynamicFieldForm = this._dynamicFieldForm$.getValue();

    dynamicFieldForm?.propertiesControls.units?.setValue(numberUnit);

    this._dynamicFieldForm$.next(dynamicFieldForm);
  }

  public set customChoiceAnswers(customChoiceAnswers: ActionItem[]) {
    this._customChoiceAnswers = customChoiceAnswers;
  }

  private getUnitsData(): Observable<[ActionItem<string>[], ActionItem<Unit>[]]> {
    return forkJoin([
      this.searchService.searchResources(ResourceType.unit, 'families'),
      this.searchService.searchResources(ResourceType.unit),
    ]).pipe(
      map(([unitFamilies, units]: [ActionItem<string>[], ActionItem<Unit>[]]) => {
        this._unitFamilies$.next(
          unitFamilies
            .filter((family: ActionItem<string>) => family.item != null)
            .map((family: ActionItem<string>) => {
              family.title = family.item ?? '';
              family.id = family.item ?? '';
              return family;
            }),
        );
        const allUnits = units.map((unit: ActionItem<Unit>) => {
          unit.title = `${unit.item?.label ?? ''}${unit.item?.symbol ? ` (${unit.item.symbol})` : ''}`;
          return unit;
        });
        this._allUnits$.next(allUnits);
        return [unitFamilies, units];
      }),
    );
  }

  public getUnitByFamily(unitFamily?: string, valueDefinition?: ValueDefinition<NumberTypeDetails>): void {
    const unitDefaults = this._allUnits$
      .getValue()
      .filter((unit: ActionItem<Unit>) => unit.item?.family === unitFamily);
    this._unitDefaults$.next(unitDefaults);
    const unit: ActionItem<Unit> | undefined = unitDefaults.find(
      (unit: ActionItem<Unit>) => unit.item?.code == valueDefinition?.type_details?.units,
    );
    this.numberUnit = unit?.item?.id ?? '';
  }

  public getUnitCodeFromId(unitId: string): string {
    const unitDefaults: ActionItem<Unit>[] | undefined = this._unitDefaults$.getValue();
    return unitDefaults.find((unitDefault: ActionItem<Unit>) => unitDefault.id === unitId)?.item?.code ?? '';
  }

  public setConsolidationTrigger(triggerValue: boolean): void {
    const dynamicFieldForm = this._dynamicFieldForm$.getValue();
    if (dynamicFieldForm && triggerValue) {
      dynamicFieldForm.propertiesControls.consolidationTrigger?.enable();
      dynamicFieldForm.propertiesControls.consolidationTrigger?.value ||
        dynamicFieldForm.propertiesControls.consolidationTrigger?.setValue(ConsolidationTriggers.update_when_one_value);
    } else if (dynamicFieldForm) {
      dynamicFieldForm.propertiesControls.consolidationTrigger?.setValue(null);
      dynamicFieldForm.propertiesControls.consolidationTrigger?.disable();
    }
    this._dynamicFieldForm$.next(dynamicFieldForm);
  }

  public resetBypassConsolidationLevels(): void {
    const dynamicFieldForm = this._dynamicFieldForm$.getValue();
    if (dynamicFieldForm) {
      dynamicFieldForm.propertiesControls.bypassConsolidationLevels?.setValue(null);
    }
    this._dynamicFieldForm$.next(dynamicFieldForm);
  }
}
