import { Injectable } from '@angular/core';
import { HttpContext, HttpParams } from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';

import { AdminMetricService } from '../admin-metric/admin-metric.service';
import {
  ApiResponse,
  ApplicationApiDefinition,
  CodeCheck,
  ConditionalTriggerUpsertPayload,
  ConditionalTrigger,
  CreateUpdateValueDefinitionMappingPayload,
  FieldInformationRequest,
  IndicatorRelatedMetrics,
  ListValueDefinitionMappingsPayload,
  Metric,
  MetricCreateRequest,
  MetricLoaderFlags,
  MetricTableDefinition,
  MetricTableDefinitionUpsertPayload,
  MetricTableCalculationDefinition,
  MinimalDataRequest,
  FiscalYear,
  SearchOptions,
  ValueDefinition,
  ValueDefinitionGroup,
  ValueDefinitionMapping,
  CreateMetricTableColumnDefinitionPayload,
  MetricTableColumnDefinition,
  UpdateMetricTableColumnDefinitionPayload,
  RelatedField,
  Taxonomy,
  AddFieldRelationsRequest,
  AssociateTaxonomiesRequest,
  ValueDefinitionFrequency,
  BulkUpdateValueDefinitionFrequencyRequest,
} from '../../../models';
import { ApiService } from '../../common/api/api.service';
import { BaseService } from '../../common/base/base.service';
import { BYPASS_INTERCEPTOR_ERROR_MANAGING } from '../../../interceptors/error-interceptor/bypass-error-constant';
import { MetricApiService } from '../../types';

@Injectable({ providedIn: 'root' })
export class AdminMetricsService extends MetricApiService {
  apiName: keyof ApplicationApiDefinition = 'admin';
  resource: string;
  servicePath: string;

  constructor(
    private adminMetricService: AdminMetricService,
    private baseService: BaseService,
    private apiService: ApiService,
  ) {
    super();
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.metrics;
  }

  public search(searchOptions: SearchOptions): Observable<ApiResponse<Metric[]>> {
    return this.adminMetricService.search(searchOptions);
  }

  getMetricDefinitions(metric_id: string): Observable<ApiResponse<ValueDefinition[]>> {
    const params = new HttpParams()
      .append('load_validators', false)
      .append('load_related_equivalent', false)
      .append('load_related_core_equivalent', false);
    return this.apiService
      .get<ApiResponse<Metric>>(`${this.servicePath}${this.resource}/metrics/${metric_id}`, { params })
      .pipe(
        map((result) => {
          const value_definitions: ValueDefinition[] = [];
          for (const value_definition_group of result.data.value_definition_groups || []) {
            for (const value_definition of value_definition_group.value_definitions || []) {
              value_definitions.push(value_definition);
            }
          }
          return {
            meta: result.meta,
            errors: result.errors,
            data: value_definitions,
          };
        }),
      );
  }

  listMetrics(metricIds: string[]): Observable<ApiResponse<Metric[]>> {
    let params = new HttpParams();
    metricIds.forEach((metricId) => {
      params = params.append('metric_ids', metricId);
    });
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics`, { params });
  }

  getMetric(id: string, params?: MetricLoaderFlags): Observable<ApiResponse<Metric>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/` + id, { params });
  }

  createMetric(metricRequest: MetricCreateRequest): Observable<ApiResponse<Metric>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/metrics`, metricRequest);
  }

  updateMetric(metric_id: string, payload: any): Observable<ApiResponse<Metric>> {
    return this.apiService.put(`${this.servicePath}${this.resource}/metrics/${metric_id}`, payload);
  }

  updateMetricTags(metric_id: string, tags?: string[]): Observable<ApiResponse<Metric>> {
    const payload: any = {};
    if (tags) {
      payload.tags = tags;
    }
    return this.apiService.put(`${this.servicePath}${this.resource}/metrics/${metric_id}/tags`, payload);
  }

  checkIfMetricCodeExists(metric_code: string, metric_code_prefix?: string): Observable<ApiResponse<CodeCheck>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/metrics/code_check/${metric_code}/prefix/${metric_code_prefix ?? ''}`,
    );
  }

  // Groups

  createGroup(metricID: string, payload?: any): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricID}/value_definition_groups`,
      payload,
    );
  }

  moveGroup(metricID: string, valueDefinitionGroupID: string, position: number): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricID}/value_definition_groups/${valueDefinitionGroupID}/move/${position}`,
      {},
    );
  }

  deleteGroup(metricID: string, valueDefinitionGroupId: string): Observable<ApiResponse<Metric>> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricID}/value_definition_groups/${valueDefinitionGroupId}`,
    );
  }

  updateGroup(metricId: string, valueDefinitionGroupID: string, payload: any): Observable<ApiResponse<Metric>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupID}`,
      payload,
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }

  // Fields

  createField(metricId: string, valueDefinitionGroupId: string, payload: any): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions`,
      payload,
    );
  }

  duplicateField(valueDefinition: ValueDefinition): Observable<ApiResponse<ValueDefinition>> {
    const newValueDefinition: ValueDefinition = JSON.parse(JSON.stringify(valueDefinition));
    newValueDefinition.id = this.baseService.newGuid();
    const response: ApiResponse<ValueDefinition> = {
      meta: {},
      errors: [],
      data: newValueDefinition,
    };
    return of(response).pipe(delay(1000));
  }

  moveField(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    position: number,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/move/${position}`,
      {},
    );
  }

  public moveFieldFromGroup(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    position: number,
    targetValueDefinitionGroupId: string,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/move/${position}/${targetValueDefinitionGroupId}`,
      {},
    );
  }

  transferField(
    fromValueDefinition: ValueDefinition,
    toValueDefinitionGroup: ValueDefinitionGroup,
  ): Observable<ApiResponse<ValueDefinition>> {
    const newValueDefinition: ValueDefinition = JSON.parse(JSON.stringify(fromValueDefinition));
    newValueDefinition.value_definition_group_id = toValueDefinitionGroup.id;
    const response: ApiResponse<ValueDefinition> = {
      meta: {},
      errors: [],
      data: newValueDefinition,
    };
    return of(response).pipe(delay(1000));
  }

  updateField(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    payload: any,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}`,
      payload,
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }

  updateFieldInformation(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    payload: FieldInformationRequest,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/field_information`,
      payload,
    );
  }

  deleteField(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}`,
      undefined,
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }

  getConditionalTriggersForValueDefinition(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
  ): Observable<ApiResponse<ConditionalTrigger[]>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/conditional_triggers`,
    );
  }

  createConditionalTrigger(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    payload: ConditionalTriggerUpsertPayload,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/conditional_triggers`,
      payload,
    );
  }

  updateConditionalTrigger(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    conditionTriggerId: string,
    payload: ConditionalTriggerUpsertPayload,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/conditional_triggers/${conditionTriggerId}`,
      payload,
    );
  }

  deleteConditionalTrigger(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    conditionTriggerId: string,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/conditional_triggers/${conditionTriggerId}`,
    );
  }

  createMetricTable(metricID: string, payload: MetricTableDefinitionUpsertPayload): Observable<ApiResponse<Metric>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/metrics/${metricID}/tables`, payload);
  }

  getMetricTable(
    metricId: string,
    tableId: string,
    checkValues = false,
    loadLeafTaxonomies = false,
  ): Observable<ApiResponse<MetricTableDefinition>> {
    const params = new HttpParams()
      .append('check_values', checkValues)
      .append('load_leaf_taxonomies', loadLeafTaxonomies);
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}`, { params });
  }

  listMetricTables(): Observable<ApiResponse<MetricTableDefinition[]>> {
    return EMPTY;
  }

  updateMetricTable(
    metricId: string,
    tableId: string,
    payload: MetricTableDefinitionUpsertPayload,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}`,
      payload,
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }

  moveMetricTable(metricId: string, tableId: string, position: number): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/move/${position}`,
      {},
    );
  }

  deleteMetricTable(metricId: string, tableId: string): Observable<ApiResponse<Metric>> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}`,
      undefined,
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }

  createMetricTableTotal(metricId: string, tableId: string, payload: MetricTableCalculationDefinition) {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/totals`,
      payload,
    );
  }

  updateMetricTableTotal(metricId: string, tableId: string, payload: MetricTableCalculationDefinition) {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/totals`,
      payload,
    );
  }

  moveMetricTableTotal(
    metricId: string,
    tableId: string,
    totalId: string,
    position: number,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/totals/${totalId}/move/${position}`,
      {},
    );
  }

  deleteMetricTableTotal(metricId: string, tableId: string, totalId: string) {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/totals/${totalId}`,
    );
  }

  listValueDefinitionMappings(
    frameworkId: string,
    payload: ListValueDefinitionMappingsPayload,
  ): Observable<ApiResponse<ValueDefinitionMapping[]>> {
    return this.apiService.post<ApiResponse<ValueDefinitionMapping[]>>(
      `${this.servicePath}${this.resource}/frameworks/${frameworkId}/mappings/list`,
      payload,
    );
  }

  createValueDefinitionMapping(
    metricID: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    payload: CreateUpdateValueDefinitionMappingPayload,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricID}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/mappings`,
      payload,
    );
  }

  updateValueDefinitionMapping(
    metricID: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    mappingId: string,
    payload: CreateUpdateValueDefinitionMappingPayload,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metrics/${metricID}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/mappings/${mappingId}`,
      payload,
    );
  }

  deleteValueDefinitionMapping(
    metricID: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    mappingId: string,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricID}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/mappings/${mappingId}`,
    );
  }

  getRelatedMetrics(id: string): Observable<ApiResponse<IndicatorRelatedMetrics>> {
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/${id}/related_metrics`);
  }

  public getTaxonomies(
    id: string,
    completeFrameworks = false,
    order_by?: string,
    order_by_direction?: 'asc' | 'desc',
  ): Observable<ApiResponse<Taxonomy[]>> {
    const params = this.getTaxonomiesParams(completeFrameworks, order_by, order_by_direction);
    return this.apiService.get(`${this.servicePath}${this.resource}/metrics/${id}/taxonomies`, { params });
  }

  updateMetricTableInformation(
    metricId: string,
    tableId: string,
    payload: FieldInformationRequest,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/field_information`,
      payload,
    );
  }

  getLatestFiscalYearWithCollectValues(): Observable<ApiResponse<FiscalYear | null>> {
    return EMPTY;
  }

  deactivateValueDefinition(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/deactivate`,
    );
  }

  deactivateValueDefinitionGroup(): Observable<ApiResponse<Metric>> {
    return EMPTY;
  }

  activateValueDefinitionGroup(): Observable<ApiResponse<Metric>> {
    return EMPTY;
  }

  deactivateMetricTable(metricId: string, metricTableId: string): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${metricTableId}/deactivate`,
    );
  }

  activateMetricTable(metricId: string, metricTableId: string): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${metricTableId}/activate`,
    );
  }

  activateValueDefinition(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/activate`,
    );
  }

  public listMetricDataRequests(): Observable<ApiResponse<MinimalDataRequest[]>> {
    return EMPTY;
  }

  public createMetricTableColumnDefinition(
    metricId: string,
    tableId: string,
    payload: CreateMetricTableColumnDefinitionPayload,
  ): Observable<ApiResponse<MetricTableColumnDefinition>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/column_definitions`,
      payload,
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }

  public deleteMetricTableColumnDefinition(
    metricId: string,
    tableId: string,
    columnId: string,
  ): Observable<ApiResponse<MetricTableColumnDefinition>> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/column_definitions/${columnId}`,
      {},
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }

  public deleteMetricTableColumnOption(
    metricId: string,
    tableId: string,
    columnId: string,
    optionId: string,
  ): Observable<undefined> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/column_definitions/${columnId}/options/${optionId}`,
      {},
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }

  public updateMetricTableColumnDefinition(
    metricId: string,
    tableId: string,
    columnId: string,
    payload: UpdateMetricTableColumnDefinitionPayload,
  ): Observable<ApiResponse<MetricTableColumnDefinition>> {
    return this.apiService.put(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/column_definitions/${columnId}`,
      payload,
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }

  public moveMetricTableColumnDefinition(
    metricId: string,
    tableId: string,
    columnId: string,
    position: number,
  ): Observable<ApiResponse<MetricTableColumnDefinition>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${tableId}/column_definitions/${columnId}/move/${position}`,
      {},
      undefined,
      false,
      new HttpContext().set(BYPASS_INTERCEPTOR_ERROR_MANAGING, true),
    );
  }

  public publishMetric(metricId: string): Observable<ApiResponse<Metric>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/metrics/${metricId}/publish`, {});
  }

  public publishField(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
  ): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/publish`,
      {},
    );
  }

  public publishMetricTable(metricId: string, metricTableDefinitionId: string): Observable<ApiResponse<Metric>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${metricTableDefinitionId}/publish`,
      {},
    );
  }

  public addFieldRelatedFields(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    payload: AddFieldRelationsRequest,
  ): Observable<ApiResponse<RelatedField[]>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/field_relations`,
      payload,
    );
  }

  public addMetricTableRelatedFields(
    metricId: string,
    metricTableDefinitionId: string,
    payload: AddFieldRelationsRequest,
  ): Observable<ApiResponse<RelatedField[]>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${metricTableDefinitionId}/field_relations`,
      payload,
    );
  }

  public deleteFieldRelatedFields(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    relatedFieldId: string,
  ): Observable<undefined> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/field_relations/${relatedFieldId}`,
    );
  }

  public deleteMetricTableRelatedFields(
    metricId: string,
    metricTableDefinitionId: string,
    relatedFieldId: string,
  ): Observable<undefined> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${metricTableDefinitionId}/field_relations/${relatedFieldId}`,
    );
  }

  public getFieldRelatedFields(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
  ): Observable<ApiResponse<RelatedField[]>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/field_relations`,
    );
  }

  public getMetricTableRelatedFields(
    metricId: string,
    metricTableDefinitionId: string,
  ): Observable<ApiResponse<RelatedField[]>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${metricTableDefinitionId}/field_relations`,
    );
  }

  public getFieldTaxonomies(
    metricId: string,
    vdgId: string,
    vdId: string,
    completeFrameworks = false,
    frequencyCode?: string,
    order_by?: string,
    order_by_direction?: 'asc' | 'desc',
  ): Observable<ApiResponse<Taxonomy[]>> {
    const params = this.getTaxonomiesParams(completeFrameworks, order_by, order_by_direction);
    return this.apiService.get(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${vdgId}/value_definitions/${vdId}/taxonomies`,
      { params },
    );
  }

  public getMetricTableTaxonomies(
    metricId: string,
    metricTableDefinitionId: string,
    completeFrameworks = false,
    frequencyCode?: string,
    order_by?: string,
    order_by_direction?: 'asc' | 'desc',
  ): Observable<ApiResponse<Taxonomy[]>> {
    const params = this.getTaxonomiesParams(completeFrameworks, order_by, order_by_direction);
    return this.apiService.get(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${metricTableDefinitionId}/taxonomies`,
      { params },
    );
  }

  public addTaxonomies(metricId: string, payload: AssociateTaxonomiesRequest): Observable<ApiResponse<Taxonomy[]>> {
    return this.apiService.post(`${this.servicePath}${this.resource}/metrics/${metricId}/taxonomies`, payload);
  }

  public addFieldTaxonomies(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    payload: AssociateTaxonomiesRequest,
  ): Observable<ApiResponse<Taxonomy[]>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/taxonomies`,
      payload,
    );
  }

  public addMetricTableTaxonomies(
    metricId: string,
    metricTableDefinitionId: string,
    payload: AssociateTaxonomiesRequest,
  ): Observable<ApiResponse<Taxonomy[]>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${metricTableDefinitionId}/taxonomies`,
      payload,
    );
  }

  public deleteTaxonomy(metricId: string, taxonomyId: string): Observable<undefined> {
    return this.apiService.delete(`${this.servicePath}${this.resource}/metrics/${metricId}/taxonomies/${taxonomyId}`);
  }

  public deleteFieldTaxonomy(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
    taxonomyId: string,
  ): Observable<undefined> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/taxonomies/${taxonomyId}`,
    );
  }

  public deleteMetricTableTaxonomy(
    metricId: string,
    metricTableDefinitionId: string,
    taxonomyId: string,
  ): Observable<undefined> {
    return this.apiService.delete(
      `${this.servicePath}${this.resource}/metrics/${metricId}/tables/${metricTableDefinitionId}/taxonomies/${taxonomyId}`,
    );
  }

  private getTaxonomiesParams(
    completeFrameworks = false,
    order_by?: string,
    order_by_direction?: 'asc' | 'desc',
  ): HttpParams {
    let params = new HttpParams().append('complete_frameworks', completeFrameworks);
    if (order_by) {
      params = params.append('order_by', order_by);
    }
    if (order_by_direction) {
      params = params.append('order_by_direction', order_by_direction);
    }

    return params;
  }

  listValueDefinitionFrequencies(
    _metricId: string,
    _valueDefinitionGroupId: string,
    _valueDefinitionId: string,
  ): Observable<ApiResponse<ValueDefinitionFrequency[]>> {
    return EMPTY;
  }

  bulkUpdateValueDefinitionFrequencies(
    _metricId: string,
    _valueDefinitionGroupId: string,
    _valueDefinitionId: string,
    _bulkUpdateRequest: BulkUpdateValueDefinitionFrequencyRequest,
  ): Observable<ApiResponse<ValueDefinitionFrequency[]>> {
    return EMPTY;
  }

  getMetricFieldVisibility() {
    return EMPTY;
  }
}
