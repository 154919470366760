import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ApiResponse, ApplicationApiDefinition, ValueDefinitionFrequencyStructure } from '../../../models';
import { ApiService } from '../../common/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ClientPublicMetricsService {
  apiName: keyof ApplicationApiDefinition = 'public';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.public.resources.metrics;
  }

  listValueDefinitionFrequencies(
    metricId: string,
    valueDefinitionGroupId: string,
    valueDefinitionId: string,
  ): Observable<ApiResponse<ValueDefinitionFrequencyStructure[]>> {
    return this.apiService.get(
      `${this.servicePath}${this.resource}/metrics/${metricId}/value_definition_groups/${valueDefinitionGroupId}/value_definitions/${valueDefinitionId}/frequencies`,
    );
  }
}
