<div class="field-position-relative" [ngSwitch]="valueDefinitionDisplayType">
  <div
    [class]="'field-focus ' + (valueFormControl.valueRef | valueDefinitionFieldSize)"
    [libIsActiveField]="focusedField$ | async"
    [focusFieldUniqueId]="focusFieldUniqueId$ | async"
    [value]="valueFormControl.valueRef"
    [focusId]="focusId"
    [ngClass]="{
      focused: isFocusable && isFocusEnabled,
      'has-comments':
        this.valueFormControl.valueRef.unresolved_regular_comments_and_replies ||
        !!this.valueFormControl.valueRef.unresolved_rejection_comments_and_replies,
    }"
    (mousedown)="selectFieldForFocus(valueFormControl.valueRef, valueGroup)"
    data-testid="field-focus"
  >
    <ng-container *ngSwitchCase="eValueDefinitionDisplayType.subtitle">
      <lib-metric-editor-subtitle [valueFormControl]="valueFormControl"></lib-metric-editor-subtitle>
    </ng-container>
    <ng-container *ngSwitchCase="eValueDefinitionDisplayType.tip">
      <lib-metric-editor-tip [valueFormControl]="valueFormControl"></lib-metric-editor-tip>
    </ng-container>
    <ng-container *ngSwitchCase="eValueDefinitionDisplayType.text_simple">
      <lib-metric-editor-text-field [valueFormControl]="valueFormControl" #valueFieldRef></lib-metric-editor-text-field>
    </ng-container>
    <ng-container *ngSwitchCase="eValueDefinitionDisplayType.text_area">
      <lib-metric-editor-text-area-field [valueFormControl]="valueFormControl"></lib-metric-editor-text-area-field>
    </ng-container>
    <ng-container *ngSwitchCase="eValueDefinitionDisplayType.text_rich">
      <lib-metric-editor-rich-text
        (editorBlur)="onBlur()"
        [valueFormControl]="valueFormControl"
      ></lib-metric-editor-rich-text>
    </ng-container>
    <ng-container *ngSwitchCase="eValueDefinitionDisplayType.document">
      <lib-metric-editor-document-link
        [valueFormControl]="valueFormControl"
        [documentContext]="documentContext"
      ></lib-metric-editor-document-link>
    </ng-container>
    <ng-container *ngSwitchCase="eValueDefinitionDisplayType.file_v2">
      <lib-metric-editor-file-attachment-v2
        [valueFormControl]="valueFormControl"
        [documentContext]="documentContext"
      ></lib-metric-editor-file-attachment-v2>
    </ng-container>
    <ng-container *ngSwitchCase="eValueDefinitionDisplayType.boolean">
      <lib-metric-editor-boolean [valueFormControl]="valueFormControl"></lib-metric-editor-boolean>
    </ng-container>
    <ng-container *ngSwitchCase="eValueDefinitionDisplayType.date">
      <lib-metric-editor-date-field [valueFormControl]="valueFormControl"></lib-metric-editor-date-field>
    </ng-container>
    <ng-container *ngSwitchCase="eValueDefinitionDisplayType.metric">
      <lib-metric-editor-metric-link
        [valueFormControl]="valueFormControl"
        (metricLinkEdit)="metricLinkEdit.emit($event)"
      >
      </lib-metric-editor-metric-link>
    </ng-container>
    <ng-container
      *ngSwitchCase="
        [
          eValueDefinitionDisplayType.choice,
          eValueDefinitionDisplayType.choice_multiple,
          eValueDefinitionDisplayType.choice_searchable,
          eValueDefinitionDisplayType.choice_radio,
        ] | multiSwitchCase: valueDefinitionDisplayType
      "
    >
      <lib-metric-editor-choice-field
        [valueFormControl]="valueFormControl"
        [indicator]="indicator"
        [isPublic]="documentContext.itemType === eItemType.public_data_requests_request"
        [integrationType]="integrationType"
      ></lib-metric-editor-choice-field>
    </ng-container>
    <ng-container
      *ngSwitchCase="
        [eValueDefinitionDisplayType.number, eValueDefinitionDisplayType.calculated]
          | multiSwitchCase: valueDefinitionDisplayType
      "
    >
      <lib-metric-editor-numeric-field
        [valueFormControl]="valueFormControl"
        [indicatorId]="indicatorId"
        [vgsetId]="vgsetId"
        [groupId]="valueGroup.id"
        [itemType]="documentContext.itemType"
        [sourceConfiguration]="sourceConfiguration"
      ></lib-metric-editor-numeric-field>
    </ng-container>

    <div
      lib-field-comment-counter
      *ngIf="
        valueFormControl.valueRef.unresolved_regular_comments_and_replies ||
        !!valueFormControl.valueRef.unresolved_rejection_comments_and_replies
      "
      class="comments-count"
      [unresolved_regular_comments_and_replies]="valueFormControl.valueRef.unresolved_regular_comments_and_replies"
      [unresolved_rejection_comments_and_replies]="valueFormControl.valueRef.unresolved_rejection_comments_and_replies"
      (actionSelected)="selectFieldForFocus(valueFormControl.valueRef, valueGroup, $event)"
    ></div>
    <div
      lib-field-actions
      data-testid="fieldActions"
      *ngIf="displayFieldActions"
      [valueDefinitionType]="valueFormControl.valueRef.type"
      [unresolvedRejectionCommentsAndReplies]="valueFormControl.valueRef.unresolved_rejection_comments_and_replies"
      [valueGroupSetStatus]="valueGroupSetStatus"
      [dataRequestSourceStatus]="dataRequestSourceStatus"
      [collaboratorResponsibility]="collaboratorResponsibility"
      (actionSelected)="selectFieldForFocus(valueFormControl.valueRef, valueGroup, $event)"
    ></div>
  </div>
</div>
