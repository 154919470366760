import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-landing-page-header',
  templateUrl: './landing-page-header.component.html',
  styleUrl: './landing-page-header.component.scss',
})
export class LandingPageHeaderComponent {
  @Input() title: string = '';
}
