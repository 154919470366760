import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

import { combineLatestWith, filter, map } from 'rxjs/operators';
import { EMPTY, Observable, forkJoin, switchMap } from 'rxjs';

import {
  ActionItem,
  ApiResponse,
  FilterBarSelection,
  OptionList,
  OptionListCategory,
  OptionListItem,
  PaginationState,
  ResourceType,
} from '../../models';
import { OptionListsApiService } from '../../services/api-services';
import { DEFAULT_PAGE_SIZE } from '../../data-table';
import { ActionItemUtils } from '../../classes';
import { tapResponse } from '@ngrx/operators';

export interface FilterState {
  active?: string;
  searchQuery?: string;
}

export interface OptionListState {
  filterState: FilterState;
  isLoading: boolean;
  optionList?: OptionList;
  optionListCategories: ActionItem<OptionListCategory>[];
  optionListCategory?: OptionListCategory;
  optionListItems: OptionListItem[];
  paginationState: PaginationState;
  total: number;
}

@Injectable()
export class OptionListStore extends ComponentStore<OptionListState> {
  private static readonly DEFAULT_STATE: OptionListState = {
    filterState: {},
    isLoading: true,
    optionListCategories: [],
    optionListItems: [],
    paginationState: { currentPage: 1, pageSize: DEFAULT_PAGE_SIZE },
    total: 0,
  };

  public readonly filterState$: Observable<FilterState> = this.select((state) => state.filterState);
  public readonly isLoading$: Observable<boolean> = this.select((state) => state.isLoading);
  public readonly optionList$: Observable<OptionList> = this.select((state) => state.optionList).pipe(
    filter((optionList): optionList is OptionList => Boolean(optionList)),
  );
  public readonly optionListCategories$: Observable<ActionItem<OptionListCategory>[]> = this.select(
    (state) => state.optionListCategories,
  );
  public readonly optionListCategory$: Observable<OptionListCategory | undefined> = this.select(
    (state) => state.optionListCategory,
  );
  public readonly optionListItems$: Observable<OptionListItem[]> = this.select((state) => state.optionListItems);
  public readonly paginationState$: Observable<PaginationState> = this.select((state) => state.paginationState);
  public readonly total$: Observable<number> = this.select((state) => state.total);

  constructor(private readonly optionListsApiService: OptionListsApiService) {
    super(OptionListStore.DEFAULT_STATE);
  }

  public initialize(optionListId: string) {
    forkJoin([
      this.optionListsApiService.getOptionList(optionListId).pipe(map((res) => res.data)),
      this.optionListsApiService.listOptionListCategories().pipe(map((res) => res.data)),
    ]).subscribe(([optionList, optionListCategories]) => {
      this.patchState({
        optionList,
        optionListCategories: ActionItemUtils.resourcesToActionItem(
          optionListCategories,
          ResourceType.option_list_category,
        ),
        optionListCategory: optionListCategories.find(
          (c) => c.id === (optionList.selection_set_category_id || optionList.core_option_list_category_id),
        ),
      });
      this.fetchOptionListItems();
    });
  }

  public updateSearchQuery(searchQuery?: string): void {
    this.updateSearchQueryState(searchQuery);
  }

  public updateFilters(filters: FilterBarSelection[]): void {
    const newFilterState: FilterState = {};
    for (const filter of filters) {
      if (filter.selection[0].id !== '-1') {
        newFilterState[filter.id as keyof FilterState] = filter.selection[0].id;
      } else {
        newFilterState[filter.id as keyof FilterState] = undefined;
      }
    }
    this.updateFiltersState(newFilterState);
  }

  private readonly updateSearchQueryState = this.updater(
    (state: OptionListState, searchQuery?: string): OptionListState => ({
      ...OptionListStore.DEFAULT_STATE,
      optionList: state.optionList,
      optionListCategory: state.optionListCategory,
      filterState: { ...state.filterState, searchQuery },
    }),
  );

  private readonly updateFiltersState = this.updater(
    (state: OptionListState, newFilter: FilterState): OptionListState => ({
      ...OptionListStore.DEFAULT_STATE,
      optionList: state.optionList,
      optionListCategory: state.optionListCategory,
      filterState: { ...state.filterState, ...newFilter },
    }),
  );

  public readonly updateOptionListState = this.updater(
    (state: OptionListState, optionList: OptionList): OptionListState => ({ ...state, optionList }),
  );

  public readonly updatePaginationState = this.updater(
    (state: OptionListState, paginationState: Partial<PaginationState>): OptionListState => ({
      ...state,
      paginationState: {
        currentPage: paginationState.currentPage ?? state.paginationState.currentPage,
        pageSize: paginationState.pageSize ?? state.paginationState.pageSize,
      },
    }),
  );

  private readonly updateOptionListsState = this.updater(
    (state: OptionListState, res: ApiResponse<OptionListItem[]>): OptionListState => ({
      ...state,
      optionListItems: res.data,
      total: res.meta.total_count ?? 0,
    }),
  );

  public readonly fetchOptionListItems = this.effect((trigger$) =>
    trigger$.pipe(
      combineLatestWith(this.paginationState$, this.filterState$, this.optionList$),
      switchMap(([_, paginationState, filterState, optionList]) =>
        this.optionListsApiService.listOptionListItems(optionList.id, {
          active: filterState.active ? filterState.active === 'active' : undefined,
          order_by: 'position',
          order_by_direction: 'asc',
          page: paginationState.currentPage,
          page_size: paginationState.pageSize,
          search_term: filterState.searchQuery,
        }),
      ),
      tapResponse(
        (apiResponse: ApiResponse<OptionListItem[]>) => {
          this.updateOptionListsState(apiResponse);
          this.patchState({ isLoading: false });
        },
        (_err) => {
          this.patchState({ isLoading: false });
          return EMPTY;
        },
      ),
    ),
  );
}
